import React from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

const CartMenuItem = () => {
  const { ordersProducts } = useSelector((state) => ({
    ordersProducts: state.orders.products,
  }))

  const ordersCount = get(Object.keys(ordersProducts).filter(
    (offer) => !!ordersProducts[offer],
  ), 'length', 0)

  return (
    <a href="/orders/new/">
      Корзина
      <span>{ordersCount}</span>
    </a>
  )
}

export default CartMenuItem
