import { useCallback } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

import { captureException } from 'common/sentry'
import { reachGoal } from 'common/ym'

export const isTrackableSelector = (state) => {
  const isEmployee = get(state, ['auth', 'isEmployee'], false)
  const isStaff = get(state, ['auth', 'isStaff'], false)

  return !isStaff && !isEmployee
}

const useYM = (goalId) => {
  const isTrackable = useSelector(isTrackableSelector)

  const reach = useCallback(
    () => {
      if (isTrackable && goalId) {
        try {
          reachGoal(goalId)
        } catch (error) {
          captureException(error)
        }
      }
    },
    [goalId, isTrackable],
  )

  return reach
}

export default useYM
