import React from 'react'
import { getCityName } from 'store/utils/cityNameId'

import style from '../index.module.sass'

const DEFAULT_CITY_NAME = 'Москва'

const RenderCity = (cityName, postalCode, userCity) => (
  postalCode ? (
    <>
      <span className={style.cityName}>{cityName}</span>&nbsp;
      <span className={style.cityName}>({postalCode})</span>
    </>
  ) : getCityName(userCity, DEFAULT_CITY_NAME)
)

export default RenderCity
