import { get } from 'lodash'
import {
  handleActions,
} from 'redux-actions'

import { getSuccessType, getFailType } from 'common/api'
import { CART_FIRST_STEP, CART_SECOND_STEP, CART_THIRD_STEP, ORDER_STATUSES } from 'common/store/cart/constants'
import {
  setCartState,
  setOrderUserData,
  getCartUserData,
  getCartStatus,
} from './actions'

export const initialState = {
  cartState: CART_FIRST_STEP,
  cartStateLoading: false,
  orderUserData: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  orderUserDataFetching: false,
}

const getCartStateFromCartStatus = (status) => {
  switch (status) {
  case ORDER_STATUSES.FILLING_CREDENTIALS:
    return CART_SECOND_STEP
  case ORDER_STATUSES.DELIVERY_SELECTION:
  case ORDER_STATUSES.FINAL_CHECK:
    return CART_THIRD_STEP
  default:
    return CART_FIRST_STEP
  }
}

const cartReducer = handleActions({
  [setCartState]: (state, action) => ({
    ...state,
    cartState: get(action, ['payload', 'cartState']),
  }),
  [setOrderUserData]: (state, action) => ({
    ...state,
    orderUserData: {
      ...get(state, 'orderUserData', initialState.orderUserData),
      ...get(action, ['payload', 'userData'], {}),
    },
  }),
  [getCartUserData]: (state) => ({
    ...state,
    orderUserDataFetching: true,
  }),
  [getSuccessType(getCartUserData)]: (state, action) => {
    return ({
      ...state,
      orderUserData: action.payload,
      orderUserDataFetching: false,
    })
  },
  [getFailType(getCartUserData)]: (state) => ({
    ...state,
    orderUserDataFetching: false,
  }),
  [getCartStatus]: (state) => ({
    ...state,
    cartStateLoading: true,
  }),
  [getSuccessType(getCartStatus)]: (state, action) => ({
    ...state,
    cartState: getCartStateFromCartStatus(action.payload),
    cartStateLoading: false,
  }),
  [getFailType(getCartStatus)]: (state) => ({
    ...state,
    cartState: CART_FIRST_STEP,
    cartStateLoading: false,
  }),
}, initialState)

export default cartReducer
