import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  LOGIN_MODAL_OPEN_TYPES,
  getSelfUser as getSelfUserAction,
  openLogin as openLoginAction,
} from 'common/store/auth/actions'

import Menu from './components/Menu'
import ProfileIcon from './components/ProfileIcon'
import LoginModal from '../LoginModal'

class ProfileMenu extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isOpened: false,
      isAuthenticated: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      isAuthenticated: props.isAuthenticated,
      isOpened: props.isAuthenticated !== state.isAuthenticated ? false : state.isOpened,
    }
  }

  componentDidMount() {
    const {
      getSelfUser,
      userIsLoaded,
      userIsLoading,
    } = this.props

    if (!userIsLoaded && !userIsLoading) {
      getSelfUser()
    }
  }

  toggle = () => {
    const {
      isAuthenticated,
      openLogin,
    } = this.props
    const { isOpened } = this.state

    if (isAuthenticated) {
      this.setState((state) => ({
        isOpened: !state.isOpened,
      }))
    } else {
      openLogin({
        openType: LOGIN_MODAL_OPEN_TYPES.SIGN_IN,
      })
      if (isOpened) this.setState({ isOpened: false })
    }
  }

  onClose = () => this.setState({
    isOpened: false,
  })

  render() {
    const {
      isAuthenticated,
      isOpened,
    } = this.state

    return (
      <>
        <ProfileIcon isOpened={isOpened} onToggle={this.toggle} />
        {isAuthenticated ? (
          <Menu
            close={this.onClose}
            isOpened={isOpened}
          />
        ) : null}
        <LoginModal />
      </>
    )
  }
}

ProfileMenu.propTypes = {
  getSelfUser: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  openLogin: PropTypes.func,
  userIsLoaded: PropTypes.bool,
  userIsLoading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userIsLoaded: state.auth.isLoaded,
  userIsLoading: state.auth.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  getSelfUser: bindActionCreators(getSelfUserAction, dispatch),
  openLogin: bindActionCreators(openLoginAction, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)
