import React from 'react'
import propTypes from 'prop-types'
import style from '../index.module.sass'

const SearchHistory = (props) => {
  const searchHistoryItems = props.searchHistory.map((searchHistoryItem) => (
    <li key={searchHistoryItem}>
      <a
        onClick={() => props.onSearchHistoryItemClick(searchHistoryItem)}
        onMouseDown={(e) => e.preventDefault()}
      >
        <span>{searchHistoryItem}</span>
      </a>
    </li>
  ))
  return (
    <div className={style.search_history}>
      <ul>
        {searchHistoryItems}
        <li>
          <a
            className={style.clear_link}
            onClick={props.onClearClick}
            onMouseDown={(e) => e.preventDefault()}
          >&times; Очистить историю поиска
          </a>
        </li>
      </ul>
    </div>
  )
}

SearchHistory.propTypes = {
  searchHistory: propTypes.arrayOf(propTypes.string).isRequired,
  onClearClick: propTypes.func.isRequired,
  onSearchHistoryItemClick: propTypes.func.isRequired,
}

export default SearchHistory
