import { isNil } from 'lodash'
import React from 'react'
import InputMask from 'react-input-mask'
import propTypes from 'prop-types'

class PhoneNumberInput extends React.Component {
  onChange = (event) => {
    const formattedPhoneNumber = event.target.value.replace(/\D/g, '')
    this.props.onChange(`+${formattedPhoneNumber}`)
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    if (isNil(userInput)) return newState
    // eslint-disable-next-line no-restricted-globals
    const userInputStr = [...userInput].join().replace(/\D/g, '')

    let { value } = newState

    const selection = newState.selection

    if (userInputStr.length === 11 && userInputStr.startsWith('89')) {
      value = userInputStr.replace('8', '+7') // replace only first occurance number
    }

    return {
      value,
      selection,
    }
  }

  render() {
    return (
      <InputMask
        {...this.props}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
        mask="+7 (999) 999-99-99"
        maskChar="_"
        onChange={this.onChange}
        placeholder="+7 (___) ___-__-__"
        value={this.props.value}
      />
    )
  }
}

PhoneNumberInput.propTypes = {
  onChange: propTypes.func,
  value: propTypes.string,
}

export default PhoneNumberInput
