import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Favorite from './components/Favorite'
import FavoritesDelete from './components/FavoritesDelete'
import Comparison from './components/Comparison'
import Image from './components/Image'
import OrderedAt from './components/OrderedAt'
import Title from './components/Title'
import Offer from './components/Offer'

import styles from './index.module.sass'

const ProductCard = ({
  className,
  id,
  cover,
  isLoading,
  offers,
  onComparisonPage,
  orderedAt,
  showCart,
  showComparison,
  showFavorites,
  showFavoritesDelete,
  title,
  url,
}) => {
  return (
    <div className={classNames('prod_list', {
      [className]: className,
      product_list_purchased: orderedAt,
    })}
    >
      {orderedAt ? <OrderedAt orderedAt={orderedAt} /> : null}
      <Image image={cover} title={title} url={url} />
      <Title title={title} url={url} />
      {!isLoading ? (
        <div className={classNames('prod_list_bot', {
          [styles.purchasedBot]: orderedAt,
        })}
        >
          <Offer
            id={id}
            offers={offers}
            orderedAt={orderedAt}
            showCart={showCart}
          />
        </div>
      ) : null}
      {(showFavorites) ? <Favorite id={id} title={title} /> : null}
      {(showFavoritesDelete) ? <FavoritesDelete id={id} title={title} /> : null}
      {(showComparison) ? <Comparison id={id} onComparisonPage={onComparisonPage} title={title} /> : null}
    </div>
  )
}

ProductCard.defaultProps = {
  className: '',
  isLoading: false,
  showCart: true,
  showComparison: true,
  showFavorites: true,
}

ProductCard.propTypes = {
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      inStockCount: PropTypes.number,
    }),
  ),
  onComparisonPage: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.number,
  cover: PropTypes.shape({
    productCardDesktopImage1x: PropTypes.string,
    productCardDesktopImage2x: PropTypes.string,
    productCardDesktopImage3x: PropTypes.string,
    productCardMobileImage1x: PropTypes.string,
    productCardMobileImage2x: PropTypes.string,
    productCardMobileImage3x: PropTypes.string,
  }),
  orderedAt: PropTypes.string,
  showCart: PropTypes.bool,
  showFavorites: PropTypes.bool,
  showFavoritesDelete: PropTypes.bool,
  showComparison: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default ProductCard
