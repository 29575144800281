import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.sass'

const AppendToCart = ({
  appendToCart,
  orderedAt,
}) => (
  <div className={classNames('prod_list_add', {
    [styles.repeatAppendToCartContainer]: orderedAt,
  })}
  >
    {orderedAt ? (
      <a className={styles.repeatAppendToCart} onClick={appendToCart} role="button">Заказать ещё раз</a>
    ) : (
      <a className="btn_by_sm" onClick={appendToCart} role="button">Добавить в корзину</a>
    )}
  </div>
)

AppendToCart.propTypes = {
  appendToCart: PropTypes.func,
  orderedAt: PropTypes.string,
}

export default AppendToCart
