import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import classNames from 'classnames'

import style from './index.module.sass'

class Modal extends React.Component {
  onAfterOpen = () => {
    const { scrollToTop } = this.props

    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }

  renderChildren() {
    const { children, className, wrapped } = this.props

    if (!wrapped) { return children }

    return (
      <div
        className={style.wrap}
      >
        <div className={className}>
          {children}
        </div>
      </div>
    )
  }

  render() {
    const {
      bodyOpenClassName,
      children,
      className,
      htmlOpenClassName,
      wrapped,
      overlayClassName,
      verticalCentered,
      verticalCenteredDesktop,
      ...otherModalProps
    } = this.props

    return (
      <ReactModal
        ariaHideApp={false}
        bodyOpenClassName={bodyOpenClassName || style.pageBody}
        className={wrapped ? style.noOutline : className}
        onAfterOpen={this.onAfterOpen}
        overlayClassName={classNames(
          { [style.alignItemsCenter]: verticalCentered },
          { [style.alignItemsCenterDesktop]: verticalCenteredDesktop },
          { [overlayClassName]: overlayClassName },
          { [style.modalOverlay]: !overlayClassName },
        )}
        {...otherModalProps}
      >
        {this.renderChildren()}
      </ReactModal>
    )
  }
}

Modal.propTypes = {
  bodyOpenClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  htmlOpenClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  overlayClassName: PropTypes.string,
  verticalCentered: PropTypes.bool,
  verticalCenteredDesktop: PropTypes.bool,
  wrapped: PropTypes.bool,
  scrollToTop: PropTypes.bool,
}

Modal.defaultProps = {
  className: '',
  isOpen: true,
  scrollToTop: false,
  wrapped: true,
}

export default Modal
