import React from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import ProductCard from 'store/components/ProductCard'
import SubmitRequest from './SubmitRequest'

import style from '../index.module.sass'

const FoundProducts = ({ createSearchHistoryItem, foundBrands, foundProducts, query }) => {
  const productsNotFound = isEmpty(foundProducts)

  return (
    <div className="search_podbor">
      <div className="search_podbor_df">
        <div className={classNames('search_podbor_l', {
          [style.notFoundContainer]: productsNotFound,
        })}
        >
          <div className="search_podbor_t">
            {productsNotFound ? 'Ничего не найдено. Попробуйте поискать в каталоге.' : 'Подходящие товары'}
          </div>

          {productsNotFound ? (
            <SubmitRequest query={query} />
          ) : null}

          {!productsNotFound && foundProducts.map(
            (product) => (
              <span
                key={product.id}
                onClick={() => { createSearchHistoryItem(query) }}
              >
                <ProductCard
                  className={style.prod_list_sm}
                  showCart={false}
                  showComparison={false}
                  showFavorites={false}
                  {...product}
                />
              </span>
            ),
          )}

          <div className="clear" />
          <div className="search_podbor_all">
            <a aria-label="product" href={`${Urls['catalog:index']()}?search=${query}`}>
              <span>{productsNotFound ? 'Перейти в каталог' : 'Показать все товары'}</span>
            </a>
          </div>
        </div>
        {!productsNotFound ? (
          <div className="search_podbor_r">
            <div className="search_podbor_t">Бренды</div>
            {foundBrands.map((brand) => (
              <div key={brand.id} className="search_brend">
                <a
                  aria-label={brand.title}
                  href={brand.url}
                >
                  <img
                    alt={brand.title}
                    src={brand.logo}
                  />
                </a>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

FoundProducts.propTypes = {
  createSearchHistoryItem: PropTypes.func,
  foundBrands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      logo: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  foundProducts: PropTypes.arrayOf(PropTypes.shape({
    biggestOldPrice: PropTypes.number,
    brand: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      logo: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    })),
    id: PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string })),
    smallestPrice: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
  })),
  query: PropTypes.string,
}

export default FoundProducts
