import React from 'react'
import classNames from 'classnames'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'

import getUserCityName from 'common/store/auth/selectors/get-user-city-name'

import style from '../../index.module.sass'

const ChooseCity = ({
  citiesDiv,
  cityInputValue,
  onChange,
  switchToManualMode,
}) => {
  const {
    postalCode,
    cityName,
  } = useSelector((state) => ({
    postalCode: state.auth.postalCode,
    cityName: getUserCityName(state),
  }))

  return (
    <div className={style.topCityDrop}>
      <div className={style.topSelectedCity}><span>{cityName}</span> {!postalCode ? <a href={Urls['main:pickupPoints']()}>смотреть пункты выдачи и постаматы</a> : null }</div>
      <div className={classNames(style.citySearch, 'search')}>
        <input className="search_inp ym-record-keys" name="search-city" onChange={onChange} placeholder="Введите название вашего города" type="text" value={cityInputValue} />
        <input className="search_sub ym-record-keys" type="submit" value="" />
      </div>
      <div>
        <ul className={style.topCityUl}>
          {citiesDiv}
        </ul>
        <ul className={classNames(style.topCityUl, style.manualMode)}>
          <li
            className={classNames({ [style.topCityB]: true })}
            onClick={switchToManualMode}
            role="menuitem"
          >
            <a>
              Указать индекс и город вручную
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

ChooseCity.propTypes = {
  citiesDiv: propTypes.arrayOf(propTypes.node),
  cityInputValue: propTypes.string,
  onChange: propTypes.func,
  switchToManualMode: propTypes.func,
}

export default ChooseCity
