import { client, getSuccessType, getFailType } from 'common/api'
import { stringify } from 'querystring'
import { get } from 'lodash'
import { createAction } from 'redux-actions'

import { CANCELLABLE_REQUEST_ID } from 'common/api/constants'
import { setUserCity } from '../auth/actions'
import {
  CITIES_LIMIT,
  ACTION_TYPES,
} from './constants'
import named from '../named'

export const getCities = named(
  ACTION_TYPES.GET_CITIES,
  (search = '') => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_CITIES,
    })

    const searchParams = {
      limit: CITIES_LIMIT,
    }

    if (search) searchParams.search = search

    const searchQuery = `?${stringify(searchParams)}`

    const response = await client.fetch(`locations/cities/${searchQuery}`)

    dispatch({
      type: response.ok ? getSuccessType(ACTION_TYPES.GET_CITIES) : getFailType(ACTION_TYPES.GET_CITIES),
      payload: response,
    })
  },
)

const getCurrentCityFromApi = (coords = {}) => async (dispatch) => {
  const latitude = get(coords, 'latitude')
  const longitude = get(coords, 'longitude')

  const searchParams = latitude && longitude ? {
    latitude,
    longitude,
  } : null

  const searchQuery = searchParams ? `?${stringify(searchParams)}` : ''

  const response = await client.fetch(`locations/cities/current/${searchQuery}`, {
    [CANCELLABLE_REQUEST_ID]: 'GET::GET-CURRENT-CITY',
  })
  const cityId = get(response, ['data', 'id'])

  if (response.ok && cityId) {
    dispatch(setUserCity(cityId))
  }
}

export const getCurrentCity = () => async (dispatch) => {
  // Первоначально пробуем получить город по ip адресу
  dispatch(getCurrentCityFromApi())

  if (navigator && navigator.geolocation) {
    // Если дано разрешение для геолокации, то получим город по координатам
    navigator.geolocation.getCurrentPosition((position) => {
      const coords = get(position, 'coords')
      dispatch(getCurrentCityFromApi(coords))
    })
  }
}

export const updateSelectCityModalOpenState = createAction(ACTION_TYPES.UPDATE_SELECT_CITY_MODAL_OPEN_STATE)
export const openSelectCityModal = createAction(ACTION_TYPES.OPEN_SELECT_CITY_MODAL)
export const closeSelectCityModal = createAction(ACTION_TYPES.CLOSE_SELECT_CITY_MODAL)
