import { get } from 'lodash'
import { handleActions } from 'redux-actions'

import { showNotification, hideNotification } from './actions'

const initialState = {
  notifications: [],
}

export default handleActions({
  [showNotification]: (state, action) => ({ notifications: [
    ...get(state, 'notifications', []),
    get(action, 'payload'),
  ] }),
  [hideNotification]: (state, action) => ({
    notifications: get(state, 'notifications', []).filter((notification) => notification.id !== action.payload),
  }),
}, initialState)
