import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import OutsideClickHandler from 'sharedComponents/OutsideClickHandler'
import MenuFooter from './MenuFooter'
import MobileNav from './MobileNav'
import style from '../index.module.sass'

const MAX_CATEGORY_DEPTH = 3

class MobileMenu extends React.Component {
  getParentCategory = (parentPath) => {
    const { categories } = this.props
    if (parentPath.length === 1) {
      return categories.find((category) => category.id === parentPath[0])
    }
    if (parentPath.length === 2) {
      return categories.find((category) => category.id === parentPath[0]).children
        .find((category) => category.id === parentPath[1])
    }
    return undefined
  }

  categorySiblings = (path) => {
    const { categories } = this.props

    if (isEmpty(path)) { return categories }

    return path.reduce((accumulator, categoryId) => (
      accumulator.find(({ id }) => (id === categoryId)).children
    ), categories)
  }

  renderPath() {
    const { categories, currentPath, setPath } = this.props
    const pathTree = currentPath.map((_categoryId, index, array) => (array.slice(0, index + 1)))
    const categoryPath = pathTree[pathTree.length - 1]
    return (
      <>
        <ul className={(pathTree.length === 1) ? style.navL : style.navLv2}>

          {(pathTree.length > 1) ? (
            <li className={style.backCat}>
              <a
                href="#"
                onClick={setPath([categories[0].id])}
              >Вернуться в Каталог
              </a>
            </li>
          ) : null}

          {(pathTree.length > 2) ? (
            <li className={style.backCat}>
              <a onClick={setPath(pathTree[pathTree.length - 2])}>
                Вернуться в &quot;{this.getParentCategory(pathTree[pathTree.length - 2]).title}&quot;
              </a>
            </li>
          )
            : null}

          {(pathTree.length > 1) ? (
            <>
              <li className={style.navT2}>{this.getParentCategory(pathTree[pathTree.length - 2]).title}</li>
              <li><a href={this.getParentCategory(pathTree[pathTree.length - 2]).url}>Посмотреть все товары</a></li>
            </>
          ) : null}

          {this.categorySiblings(categoryPath.slice(0, categoryPath.length - 1)).map((category) => {
            return (
              <li key={category.id}>
                <a
                  className={classNames({
                    [style.navPar]: category.children.length && pathTree.length === 1,
                    [style.navPar2]: category.children.length && pathTree.length !== 1 &&
                        pathTree.length < MAX_CATEGORY_DEPTH,
                  })}
                  href={(category.children.length && pathTree.length < MAX_CATEGORY_DEPTH) ? '#' : category.url}
                  onClick={(category.children.length) ? setPath(category.children[0].path) : setPath(category.path)}
                >
                  {category.title}
                </a>
              </li>
            )
          })}
        </ul>
        {(pathTree.length === 1) ? <MobileNav /> : null}
      </>
    )
  }

  render() {
    const { navHide } = this.props

    return (
      <>
        <div className={style.mask} />

        <OutsideClickHandler
          callback={navHide}
          ignoredClassNames={['show_nav_mob']}
        >
          <div className={style.nav}>
            {this.renderPath()}
            <MenuFooter />
          </div>
        </OutsideClickHandler>
      </>
    )
  }
}

const CategoryType = () => {
  return PropTypes.shape({
    childrens: PropTypes.arrayOf(CategoryType),
    id: PropTypes.number,
    instrumentsIcon: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    // eslint-disable-next-line prefer-rest-params
  }).apply(this)
}

MobileMenu.propTypes = {
  categories: PropTypes.arrayOf(CategoryType),
  currentPath: PropTypes.arrayOf(PropTypes.number),
  setPath: PropTypes.func,
  navHide: PropTypes.func,
}

export default MobileMenu
