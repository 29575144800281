import React from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'

import IconWrapper from './IconWrapper'
import styles from './index.module.sass'

const ArrowIcon = ({ className, ...wrapperProps }) => (
  <IconWrapper {...wrapperProps}>
    <svg
      className={classNames(styles.icon, { [className]: className })}
      fill="none"
      height="16"
      viewBox="0 0 10 16"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 1L2 8L9 15" strokeWidth="2.5" />
    </svg>
  </IconWrapper>
)

ArrowIcon.propTypes = {
  className: propTypes.string,
}

ArrowIcon.defaultProps = {
  className: '',
}

export default ArrowIcon
