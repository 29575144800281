import AbortController from 'abort-controller'
import es6Promise from 'es6-promise'
import fetch from 'isomorphic-fetch'
import { get, isObject } from 'lodash'

import { CANCELLABLE_REQUEST_ID } from './constants'
import {
  CONTENT_TYPES,
  DEFAULT_HEADERS,
  HEADERS,
  filterHeaders,
  normalizeHeaders,
} from './utils/headers'
import { join } from './utils/path'

import {
  transformRequestBody,
  transformResponseBody,
} from './utils/transformations'

es6Promise.polyfill()

/*
Добавил кастомное поле data в options запроса, для того что бы делать с ним стандартные преобразвания,
на подобие camelCaseToSnakeCase и JSON.stringify.
В ответ добавил кстомное поле data, с применеными JSON.parse и snakeCaseToCamelCase.
Все GET запросы являются отменяемыми. Если выполняются несколько одинаковых запросов,
то выполняется только последний, остальные отменяются.
Что бы запрос был отменяемым нужно передать в options ключ
запроса для отмены - cancellableRequestId: 'myId'.
 */
class Client {
  #requestsInProgress

  constructor(baseUrl, options) {
    this.baseUrl = baseUrl
    this.options = options || {}
    this.#requestsInProgress = {}
  }

  getHeader = (headers, header) => (headers instanceof Headers ? headers.get(header) : get(headers, header))

  isSuitableTransform = (options) => {
    const headers = get(options, 'headers')
    const contentType = this.getHeader(headers, HEADERS.CONTENT_TYPE)

    return contentType && contentType.toLowerCase().includes(CONTENT_TYPES.JSON)
  }

  transformBody = (body) => (body && isObject(body) ? JSON.stringify(transformRequestBody(body)) : body)

  transformRequest = (options) => {
    return this.isSuitableTransform(options) ? {
      ...options,
      body: this.transformBody(get(options, 'data')),
    } : options
  }

  getRequestOptions = (options) => {
    const mergedOptions = {
      ...this.options,
      ...options,
    }

    const currentHeaders = filterHeaders(get(mergedOptions, 'headers', {}))
    const normalizedHeaders = normalizeHeaders(currentHeaders)

    return this.transformRequest({
      ...mergedOptions,
      headers: normalizedHeaders,
    })
  }

  transformResponseBody = async (response) => {
    try {
      const data = await response.json()
      response.data = transformResponseBody(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }

    return response
  }

  transformResponse = async (response) => (
    this.isSuitableTransform(response) ?
      this.transformResponseBody(response) :
      response
  )

  appendDefaultHeaders = (options) => {
    const currentHeaders = get(options, 'headers', {})
    const defaultHeaders = get(this.options, 'headers', {})

    return {
      ...options,
      headers: {
        ...defaultHeaders,
        ...currentHeaders,
      },
    }
  }

  #getCancellableRequestId = (url, options) => get(options, CANCELLABLE_REQUEST_ID)

  #isCancellableRequest = (options) => !!get(options, CANCELLABLE_REQUEST_ID, false)

  #setRequestInProgress = (url, options) => {
    if (this.#isCancellableRequest(options)) {
      const cancellableRequestId = this.#getCancellableRequestId(url, options)
      const currentRequestController = this.#requestsInProgress[cancellableRequestId]

      if (currentRequestController) {
        currentRequestController.abort()
      }

      const requestController = new AbortController()

      this.#requestsInProgress[cancellableRequestId] = requestController

      return requestController.signal
    }

    return undefined
  }

  #setRequestIsFinished = (url, options) => {
    if (this.#isCancellableRequest(options)) {
      const requestKey = this.#getCancellableRequestId(url, options)
      this.#requestsInProgress[requestKey] = undefined
    }
  }

  fetch = async (url, options) => {
    const currentUrl = join(this.baseUrl, url)

    const signal = this.#setRequestInProgress(currentUrl, options)

    const currentOptions = this.getRequestOptions(
      this.appendDefaultHeaders(options),
    )

    const response = await fetch(
      currentUrl,
      {
        ...currentOptions,
        signal,
      },
    )

    this.#setRequestIsFinished(currentUrl, options)

    return this.transformResponse(response)
  }
}

const client = new Client(
  '/api/v01/',
  {
    headers: DEFAULT_HEADERS,
  },
)

export default client
