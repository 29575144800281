import React, { useCallback } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  deleteFavoriteProduct,
} from 'common/store/favorite/actions'
import style from '../index.module.sass'

const FavoritesDelete = ({ id, title }) => {
  const dispatch = useDispatch()

  const handleDelete = useCallback((event) => {
    event.preventDefault()

    dispatch(deleteFavoriteProduct(id, title))
  }, [dispatch, id, title])

  return (
    <a
      className={
        classNames(
          style.deleteIcon,
        )
      }
      href="#"
      onClick={handleDelete}
      role="button"
    >
      Понравилось
    </a>
  )
}

FavoritesDelete.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
}

export default FavoritesDelete
