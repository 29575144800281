import React, { useCallback } from 'react'
import classNames from 'classnames'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  authenticate,
  sendAuthenticationCode,
  updateUserTemporaryPhoneNumber,
  LOGIN_MODAL_OPEN_TYPES,
} from 'common/store/auth/actions'
import { CloseIcon } from 'sharedComponents/icons'
import PhoneNumberInput from 'sharedComponents/PhoneNumberInput'
import { getLoginTitle, getLoginSubTitle } from './utils'
import TelegramLoginButton from '../TelegramLoginButton'

import styles from './index.module.sass'

const getReceiveSmsCodeButtonClassName = (openType) => {
  switch (openType) {
  case LOGIN_MODAL_OPEN_TYPES.UPDATE_PHONE_NUMBER:
  case LOGIN_MODAL_OPEN_TYPES.DURING_CHECKOUT:
    return 'receive_sms_input'
  case LOGIN_MODAL_OPEN_TYPES.SIGN_IN:
  case LOGIN_MODAL_OPEN_TYPES.REDIRECT:
    return 'receive_sms_code'
  default:
    throw new Error(`Undefined open type: ${openType}`)
  }
}

const {
  AUTH_TYPES,
} = window.DJANGO_CONSTANTS

const PhoneNumberForm = ({
  onClose,
}) => {
  const dispatch = useDispatch()

  const {
    error,
    loading,
    openType,
    phoneNumber,
  } = useSelector(
    (state) => ({
      error: state.auth.sendCodeError,
      loading: state.auth.sendCodeLoading,
      openType: state.auth.openType,
      phoneNumber: state.auth.temporaryPhoneNumber,
    }),
  )

  const handleTelegramResponse = useCallback((response) => {
    dispatch(authenticate({ telegramLoginData: response }, AUTH_TYPES.TELEGRAM))
  }, [dispatch])

  const setPhoneNumber = useCallback((phoneNumberValue) => dispatch(
    updateUserTemporaryPhoneNumber(phoneNumberValue),
  ), [dispatch])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    dispatch(sendAuthenticationCode(phoneNumber))
  }, [phoneNumber, dispatch])

  return (
    <form className={styles.signIn} id="login-form-phone-number" onSubmit={handleSubmit}>
      <div className={classNames(styles.header, styles.panel)}>
        {onClose ? (
          <a
            aria-label="Close modal"
            className={styles.close}
            onClick={(event) => {
              event.preventDefault()
              onClose()
            }}
          >
            <CloseIcon className={styles.closeIcon} wrapperClassName={styles.closeIconWrapper} />
          </a>
        ) : null}
      </div>

      <div className={styles.content}>
        <div className={classNames(styles.middleContainer)}>
          <div className={styles.title}>{getLoginTitle(openType)}</div>
          <div className={styles.subTitle}>{getLoginSubTitle(openType)}</div>

          <div>
            <label
              className={classNames(styles.label, { [styles.error]: error })}
              htmlFor="phoneNumber"
            >
              {error || 'Номер телефона'}
            </label>

            <PhoneNumberInput
              className={styles.phoneInput}
              id="phoneNumber"
              name="phoneNumber"
              onChange={setPhoneNumber}
              type="tel"
              value={phoneNumber}
            />
          </div>

          <button
            className={classNames(styles.button, getReceiveSmsCodeButtonClassName(openType))}
            disabled={loading}
            type="submit"
          >
            Получить СМС код
          </button>
        </div>
        {
          openType === LOGIN_MODAL_OPEN_TYPES.SIGN_IN ?
            <TelegramLoginButton dataOnauth={handleTelegramResponse} /> :
            null
        }
        <div className={classNames(styles.bottomContainer, styles.description)}>
          Нажимая кнопку «Получить СМС код», Вы соглашаетесь с&nbsp;
          <a className={styles.link} href="/privacy-policy">политикой обработки персональных данных</a>
        </div>
      </div>
    </form>
  )
}

PhoneNumberForm.propTypes = {
  onClose: propTypes.func,
}

export default PhoneNumberForm
