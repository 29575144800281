import React from 'react'
import classNames from 'classnames'
import propTypes from 'prop-types'

import styles from './index.module.sass'

const IconWrapper = ({ wrapperClassName, children }) => (
  <div className={classNames(styles.iconWrapper, { [wrapperClassName]: wrapperClassName })}>
    {children}
  </div>
)

IconWrapper.propTypes = {
  wrapperClassName: propTypes.string,
  children: propTypes.node.isRequired,
}

export default IconWrapper
