import { get } from 'lodash'
import { handleActions } from 'redux-actions'

import { getSuccessType, getFailType } from 'common/api'
import {
  getCities,
  updateSelectCityModalOpenState,
  openSelectCityModal,
  closeSelectCityModal,
} from './actions'

const initialState = {
  cities: [],
  citiesLoading: false,
  citiesSelectIsOpen: false,
}

const locationsReducer = handleActions({
  [getCities]: (state) => ({
    ...state,
    citiesLoading: true,
  }),
  [getSuccessType(getCities)]: (state, action) => ({
    ...state,
    citiesLoading: false,
    cities: get(action, ['payload', 'data', 'results'], []),
  }),
  [getFailType(getCities)]: (state) => ({
    ...state,
    citiesLoading: false,
  }),
  [updateSelectCityModalOpenState]: (state, action) => ({
    ...state,
    citiesSelectIsOpen: action.payload,
  }),
  [closeSelectCityModal]: (state) => ({
    ...state,
    citiesSelectIsOpen: false,
  }),
  [openSelectCityModal]: (state) => ({
    ...state,
    citiesSelectIsOpen: true,
  }),
}, initialState)

export default locationsReducer
