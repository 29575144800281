export const actionTypes = {
  CREATE_ORDER_PRODUCT: 'ORDERS/CREATE_ORDER_PRODUCT',
  DELETE_ORDER_PRODUCT: 'ORDERS/DELETE_ORDER_PRODUCT',
  GET_ORDERS_DELIVERIES: 'ORDERS/GET_ORDERS_DELIVERIES',
  RESET_LAST_DELIVERIES_PICKUP_POINT: 'ORDERS/RESET_LAST_DELIVERIES_PICKUP_POINT',
  SUBMIT_ORDERS: 'ORDERS/SUBMIT_ORDERS',
  UPDATE_ORDER_PRODUCTS: 'ORDERS/UPDATE_ORDER_PRODUCTS',
  UPDATE_ORDER_DELIVERY: 'ORDERS/UPDATE_ORDER_DELIVERY',
  UPDATE_LAST_DELIVERY: 'ORDERS/UPDATE_LAST_DELIVERY',
  UPDATE_LAST_DELIVERY_WITH_ESTIMATE: 'ORDERS/UPDATE_LAST_DELIVERY_WITH_ESTIMATE',
  UPDATE_ORDER_PRODUCT_COUNT: 'ORDERS/UPDATE_ORDER_PRODUCT_COUNT',
  UPDATE_ORDERS_PAYMENT: 'ORDERS/UPDATE_ORDERS_PAYMENT',
  UPDATE_ORDERS_COMMENT: 'ORDERS/UPDATE_ORDERS_COMMENT',
}

export const PAYMENT_METHODS = {
  PREPAYMENT: 0,
  UPON_DELIVERY: 1,
}

export const PAYMENT_METHOD_LABELS = {
  [PAYMENT_METHODS.PREPAYMENT]: 'Онлайн',
  [PAYMENT_METHODS.UPON_DELIVERY]: 'При получении',
}

// For payment

export const DEFAULT_PAYMENT_METHOD = PAYMENT_METHODS.PREPAYMENT

export const DEFAULT_PAYMENT = {
  method: DEFAULT_PAYMENT_METHOD,
}

// For deliveries matching

export const DELIVERY_METHOD_TYPES = {
  COURIER: 1,
  PICKUP_POINT: 2,
  STORE: null,
}

export const DELIVERY_TYPES = [{
  title: 'Самовывоз',
  value: DELIVERY_METHOD_TYPES.PICKUP_POINT,
}, {
  title: 'Курьер',
  value: DELIVERY_METHOD_TYPES.COURIER,
}]

export const DELIVERY_TYPE_NAMES = [
  ...DELIVERY_TYPES,
  {
    title: 'Из магазина',
    value: null,
  },
]
