import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './index.module.sass'

const Loader = ({
  className,
  containerClassName,
  children,
}) => (
  <div className={classNames(style.preload, {
    [containerClassName]: containerClassName,
  })}
  >
    <div className={classNames(style.preload_vn, {
      [className]: className,
    })}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    {children || null}
  </div>
)

Loader.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.node,
}

export default Loader
