import { useCallback, useReducer } from 'react'
import {
  clearSearchHistory as clearSearchHistoryAction,
  createSearchHistoryItem as createSearchHistoryItemAction,
  debouncedPerformSearch,
  getSearchHistory as getSearchHistoryAction,
} from './actions'

import reducer, { initialState } from './reducer'

const useSearchProducts = () => {
  const [{
    foundBrands,
    foundProducts,
    searchHistory,
    isLoading,
    isTyping,
  }, dispatch] = useReducer(reducer, initialState)

  const getSearchHistory = useCallback(() => getSearchHistoryAction(dispatch), [dispatch])
  const clearSearchHistory = useCallback(() => clearSearchHistoryAction(dispatch), [dispatch])
  const performSearch = useCallback((query) => {
    if (query.trim().length > 2) {
      debouncedPerformSearch(dispatch, query.trim())
    }
  }, [dispatch])
  const createSearchHistoryItem = useCallback(
    (productName) => createSearchHistoryItemAction(dispatch, productName),
    [dispatch],
  )

  return {
    clearSearchHistory,
    createSearchHistoryItem,
    foundBrands,
    foundProducts,
    getSearchHistory,
    isLoading,
    isTyping,
    performSearch,
    searchHistory,
  }
}

export default useSearchProducts
