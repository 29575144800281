import { useCallback, useMemo } from 'react'
import { last, get, noop } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import useYM from 'common/hooks/use-ym'
import { GOALS } from 'common/ym'
import getOffersCount from 'common/store/orders/selectors/get-offers-count'
import getUpdatedOffersCount from 'common/store/orders/selectors/get-updated-offers-count'
import { createOrderProduct, updateOrderProductCount } from 'common/store/orders/actions'
import {
  ADDED_FROM_ANOTHER_PARTNER,
  NO_MORE_PRODUCTS,
  REMOVE_MESSAGES,
} from 'store/pages/cart/components/Cart/components/StepOne/components/Product/constants'
import useSelect from 'store/pages/cart/components/Cart/components/common/SelectedStore/hooks/use-select'
import getOffersLoading from 'common/store/orders/selectors/get-offers-loading'

const selectActualOffer = (offersInStock, offersInCart) => offersInStock.find(
  (offerInStock) => {
    const offerId = offerInStock.id
    const inStockCount = get(offerInStock, 'inStockCount', 0)
    const inCartCount = get(offersInCart, offerId, 0)

    return inCartCount < inStockCount
  },
)

const getOffersToSet = (
  dispatch,
  offersInStock,
  offersInCart,
  inStockCount,
  offerId,
  count,
  incrementCallback = noop,
) => {
  const newOffersInCart = []

  const currentOffer = offersInStock.find(
    (offer) => offer.id === offerId,
  )

  if (currentOffer.inStockCount >= count) {
    newOffersInCart.push({
      id: offerId,
      count,
    })
  } else {
    const otherCount = Object.keys(offersInCart).filter(
      (id) => id.toString() !== offerId.toString(),
    ).reduce(
      (otherOffersCount, id) => otherOffersCount + offersInCart[id],
      0,
    )

    newOffersInCart.push({
      id: offerId,
      count: currentOffer.inStockCount,
    })

    let remainder = count + otherCount - currentOffer.inStockCount

    offersInStock.filter(
      (offer) => offer.id !== offerId,
    ).forEach(
      (offer) => {
        const currentCount = remainder && remainder > offer.inStockCount ? offer.inStockCount : remainder
        remainder -= currentCount

        if (currentCount > 0) {
          newOffersInCart.push({
            id: offer.id,
            count: currentCount,
          })
        }
      },
    )

    if (remainder > 0) {
      incrementCallback(NO_MORE_PRODUCTS)
    } else {
      incrementCallback(REMOVE_MESSAGES)
    }
  }

  return newOffersInCart.filter(
    (offerToSet) => offerToSet.count !== offersInCart[offerToSet.id],
  )
}

const useActualOffer = (offers) => {
  const offerIds = useMemo(() => offers.map((offer) => offer.id), [offers])

  const select = useSelect()

  const dispatch = useDispatch()
  const {
    offersInCart,
    updatedOffersCount,
    isLoading,
  } = useSelector((state) => ({
    offersInCart: getOffersCount(state, offerIds),
    updatedOffersCount: getUpdatedOffersCount(state, offerIds),
    isLoading: getOffersLoading(offerIds)(state),
  }))

  const inStockCount = useMemo(
    () => offers.reduce((offerInStockCount, offer) => offerInStockCount + offer.inStockCount, 0),
    [offers],
  )
  const count = useMemo(
    () => offersInCart && Object.keys(offersInCart).reduce(
      (totalCount, offerId) => totalCount + offersInCart[offerId],
      0,
    ),
    [offersInCart],
  )

  const actualOffer = selectActualOffer(offers, offersInCart) || last(offers)

  const actualOfferId = get(actualOffer, 'id')
  const actualOfferCount = offersInCart[actualOfferId] || 0
  const oldPrice = get(actualOffer, 'oldPrice')
  const discount = get(actualOffer, 'discount', 0)
  const price = get(actualOffer, 'price')

  const reachGoal = useYM(GOALS.ADD_TO_CART_GOAL_ID)

  const appendToCart = useCallback(
    () => {
      dispatch(createOrderProduct(actualOfferId))
      reachGoal()
    },
    [actualOfferId, dispatch, reachGoal],
  )

  const updateProductCount = useCallback(
    (offerId, newCount, incrementCallback = noop) => {
      const offersToSet = getOffersToSet(
        dispatch,
        offers,
        offersInCart,
        inStockCount,
        offerId,
        newCount,
        incrementCallback,
      )

      offersToSet.forEach(
        (offerToSet) => {
          if (offerToSet.id !== offerId) {
            incrementCallback(ADDED_FROM_ANOTHER_PARTNER)
            select(offerToSet.id)
          } else {
            incrementCallback(REMOVE_MESSAGES)
          }
          if (!updatedOffersCount[offerToSet.id]) {
            dispatch(createOrderProduct(
              offerToSet.id,
              offerToSet.count,
              false,
            ))
          } else {
            dispatch(updateOrderProductCount(
              offerToSet.id,
              offerToSet.count,
            ))
          }
        },
      )
    },
    [dispatch, inStockCount, offers, offersInCart, updatedOffersCount, select],
  )

  return {
    actualOffer,
    actualOfferCount,
    appendToCart,
    count,
    inStockCount,
    isLoading,
    oldPrice,
    discount,
    price,
    updateProductCount,
  }
}

export default useActualOffer
