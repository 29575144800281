import * as Sentry from '@sentry/browser'
import YM from './integrations/ym'

export default function init() {
  Sentry.init({
    dsn: window.SENTRY_DSN,
    release: window.COMMIT_SHA,
    integrations: [
      new YM(),
    ],
  })
}

export const captureErrorMessage = (message) => Sentry.captureMessage(message)
export const captureException = (error) => Sentry.captureException(error)
