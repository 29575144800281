import { isNumber } from 'lodash'

const baseOptions = {
  minimumSignificantDigits: 1,
}

const currencyFormatter = new Intl.NumberFormat('ru-RU', {
  ...baseOptions,
  style: 'currency',
  currency: 'RUB',
})

const formatter = new Intl.NumberFormat('ru-RU', baseOptions)

export default (price, showCurrency = true) => (
  isNumber(price) ?
    (showCurrency ? currencyFormatter : formatter).format(price.toFixed(2))
    : ''
)
