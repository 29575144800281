import {
  ACTION_TYPES,
} from './constants'
import {
  createRelatedProductCreator,
  createRelatedProductDestroyer,
} from '../helpers/related-products'

export const createFavoriteProduct = createRelatedProductCreator(
  ACTION_TYPES.CREATE_FAVORITE_PRODUCT,
  'apiV01:favoriteProductsList',
  ['favorite', 'products'],
  'favoriteProducts',
)

export const deleteFavoriteProduct = createRelatedProductDestroyer(
  ACTION_TYPES.DELETE_FAVORITE_PRODUCT,
  'apiV01:favoriteProductsDetail',
  ['favorite', 'products'],
  'favoriteProducts',
)
