export const addToCart = (name, id, price, brand, category, quantity) => {
  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currencyCode: 'RUB',
      add: {
        products: [{
          name,
          id,
          price,
          brand,
          category,
          quantity,
        }],
      },
    },
  })
}

export const basketPage = (value, ids = []) => {
  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'BasketPage',
    currencyCode: 'RUB',
    value,
    items: ids.map((id) => ({
      id,
      google_business_vertical: 'retail',
    })),

  })
}

export const viewSearchResults = (ids = []) => {
  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'view_search_results',
    items: ids.map((id) => ({
      id,
      google_business_vertical: 'retail',
    })),

  })
}

export const purchase = (id, revenue, products, shipping = null, coupon = null) => {
  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'purchase',
    currencyCode: 'RUB',
    value: revenue,
    ecommerce: {
      purchase: {
        actionField: {
          id,
          revenue,
          coupon,
          shipping,
        },
        products,
      },
    },
  })
}

export const cartContactDetailsStep = () => {
  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'checkout',
    currencyCode: 'RUB',
    ecommerce: {
      checkout: {
        actionField: { step: 1, option: 'ContactDetails' },
      },
    },
  })
}

export const cartDeliveryDetailsStep = () => {
  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'checkout',
    currencyCode: 'RUB',
    ecommerce: {
      checkout: {
        actionField: { step: 2, option: 'DeliveryDetails' },
      },
    },
  })
}

export const cartPaymentStartStep = () => {
  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'checkout',
    currencyCode: 'RUB',
    ecommerce: {
      checkout: {
        actionField: { step: 3, option: 'PaymentStart' },
      },
    },
  })
}
