import React, { useEffect } from 'react'
import classNames from 'classnames'
import propTypes from 'prop-types'

import useYM from 'common/hooks/use-ym'
import { GOALS } from 'common/ym'
import styles from './index.module.sass'

const Notification = ({
  title,
  subTitle,
  action,
  isError,
}) => {
  const reachGoalError = useYM(GOALS.ERROR)

  useEffect(() => { if (isError) reachGoalError() }, [reachGoalError])

  return (
    <>
      <div className={styles.content}>
        <div
          className={classNames({
            [styles.bigTitle]: !subTitle,
            [styles.title]: subTitle,
          })}
          data-notification-item="title"
        >
          {title}
        </div>
        {subTitle ? (
          <div
            className={styles.subTitle}
            data-notification-item="subTitle"
          >
            {subTitle}
          </div>
        ) : null}
      </div>
      <div className={styles.actions}>
        {action ? (
          <a
            className={styles.action}
            data-notification-item="action"
            href={action.url}
          >
            {action.title}
          </a>
        ) : null}
        {isError ? (
          <a
            className={styles.action}
            data-notification-item="action"
            /* eslint-disable-next-line no-undef */
            onClick={() => jivo_api.open()}
          >
            Связаться с поддержкой
          </a>
        ) : null}
      </div>
    </>
  )
}

Notification.propTypes = {
  title: propTypes.string,
  subTitle: propTypes.string,
  action: propTypes.shape({
    title: propTypes.string,
    url: propTypes.string,
  }),
  isError: propTypes.bool,
}

export default Notification
