import { client, getFailType, getSuccessType } from 'common/api'
import named from 'common/store/named'
import { isPhoneNumber } from 'common/utils/formatters/phone'
import { showNotification } from 'common/store/notifications/actions'
import getSelfUser from './get-self-user'
import { ACTION_TYPES } from '../constants'

export const authenticate = named(
  ACTION_TYPES.AUTHENTICATE,
  (loginData, authType) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.AUTHENTICATE })

    const response = await client.fetch(Urls['apiV01:authenticationLogin'](), {
      method: 'POST',
      data: {
        ...loginData,
        authType,
      },
    })

    dispatch({
      type: response.ok ? getSuccessType(ACTION_TYPES.AUTHENTICATE) : getFailType(ACTION_TYPES.AUTHENTICATE),
      payload: response,
    })

    if (response.ok) {
      dispatch(getSelfUser())
      dispatch(
        showNotification({
          title: 'Вы успешно вошли!',
        }),
      )
    }
  },
)

export const logout = named(
  ACTION_TYPES.LOGOUT,
  (withNotification = true) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.LOGOUT })

    const response = await client.fetch(Urls['apiV01:authenticationLogout'](), {
      method: 'POST',
    })

    dispatch({
      type: response.ok ? getSuccessType(ACTION_TYPES.LOGOUT) : getFailType(ACTION_TYPES.LOGOUT),
      payload: response,
    })

    if (response.ok && withNotification) {
      dispatch(
        showNotification({
          title: 'Вы успешно вышли!',
        }),
      )
    }
  },
)

export const sendAuthenticationCode = named(
  ACTION_TYPES.SEND_AUTHENTICATION_CODE,
  (phoneNumber) => async (dispatch) => {
    if (phoneNumber && isPhoneNumber(phoneNumber)) {
      dispatch({ type: ACTION_TYPES.SEND_AUTHENTICATION_CODE })

      const response = await client.fetch(
        Urls['apiV01:sendAuthenticationCode'](),
        {
          method: 'POST',
          data: { phoneNumber },
        },
      )

      dispatch({
        type: response.ok ?
          getSuccessType(ACTION_TYPES.SEND_AUTHENTICATION_CODE) :
          getFailType(ACTION_TYPES.SEND_AUTHENTICATION_CODE),
        payload: response,
      })
    }
  },
)
