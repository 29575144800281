import PropTypes from 'prop-types'

import LocationsPropTypes from './locations'

const Partner = PropTypes.shape({
  id: PropTypes.number,
  label: PropTypes.string,
  organizationName: PropTypes.string,
  ordersCount: PropTypes.number,
})

const Offer = PropTypes.shape({
  id: PropTypes.number,
  inStockCount: PropTypes.number,
  partner: Partner,
  price: PropTypes.number,
  product: PropTypes.number,
})

const Store = PropTypes.shape({
  id: PropTypes.number,
  address: LocationsPropTypes.Address,
})

const FlatPartner = PropTypes.shape({
  id: PropTypes.number,
  isDistributor: PropTypes.bool,
  isDistributorAgent: PropTypes.bool,
  isSeller: PropTypes.bool,
  isSellerAgent: PropTypes.bool,
  label: PropTypes.string,
  organizationName: PropTypes.string,
  phoneNumber: PropTypes.string,
})

export default {
  Offer,
  Partner,
  Store,
  FlatPartner,
}
