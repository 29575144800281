import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'
import propTypes from 'prop-types'
import { getSuccessType } from 'common/api'
import { ACTION_TYPES } from 'common/store/auth/constants'

import paymentStyles from 'store/pages/cart/components/Cart/components/common/PaymentInfo/index.module.sass'
import style from '../../index.module.sass'

const EnterCity = ({ close }) => {
  const {
    cityNameState, postalCodeState,
  } = useSelector((state) => ({
    cityNameState: state.auth.cityName,
    postalCodeState: state.auth.postalCode,
  }))

  const [postalCode, setPostalCode] = useState(postalCodeState || '')
  const [cityName, setCityName] = useState(cityNameState || '')
  const dispatch = useDispatch()

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    dispatch({
      type: getSuccessType(ACTION_TYPES.SET_USER_CITY),
      payload: {
        data: {
          postalCode,
          cityName,
        },
      },
    })
    close()
  }, [cityName, close, dispatch, postalCode])

  return (
    <div className={classNames(style.manualInputContainer)}>
      <div className={classNames(style.title)}>Другой населенный пункт</div>
      <form onSubmit={handleSubmit}>
        <div className={style.inputText}>Введите индекс</div>
        <div className={style.inputContainer}>
          <input
            className="search_inp"
            id="postal-code"
            maxLength="6"
            onChange={(event) => setPostalCode(event.target.value)}
            required
            type="text"
            value={postalCode}
          />
        </div>

        <div className={style.inputText}>Наименование города и адрес</div>
        <div className={style.inputContainer}>
          <input
            className="search_inp"
            id="city-name"
            onChange={(event) => setCityName(event.target.value)}
            required
            type="text"
            value={cityName}
          />
        </div>

        <div className={style.submitContainer}>
          <button
            className={paymentStyles.submitButton}
            type="submit"
          >
            Подтвердить
          </button>
        </div>
      </form>
    </div>
  )
}

EnterCity.propTypes = {
  close: propTypes.func,
}

export default EnterCity
