import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import PicturePropTypes from 'shared-prop-types/picture'
import Picture from 'sharedComponents/Picture'

import style from './index.module.sass'

const SLIDER_SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  className: style.topSliderBanner,
}

const MainPageTopSliderBanner = ({ slides }) => (
  <div className="top-slider slider">
    <Slider {...SLIDER_SETTINGS}>
      {slides.map((slide) => (
        <a key={slide.id} className={style.slide} href={slide.url}>
          <Picture alt={slide.alt} picture={slide.picture} />
        </a>
      ))}
    </Slider>
  </div>
)

MainPageTopSliderBanner.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      id: PropTypes.number,
      picture: PicturePropTypes.Picture,
      url: PropTypes.string,
    }),
  ),
}

export default MainPageTopSliderBanner
