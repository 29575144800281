import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { showNotification } from 'common/store/notifications/actions'
import paymentStyles from 'store/pages/cart/components/Cart/components/common/PaymentInfo/index.module.sass'
import userFormStyle from 'store/components/UserData/index.module.sass'
import PhoneNumberInput from 'sharedComponents/PhoneNumberInput'
import useSubmitRequest from './use-submit-request'

import styles from './index.module.sass'

const validateData = (data, dispatch) => {
  let isValid = true
  if (!data.email && data.phoneNumber.length < 12) {
    dispatch(showNotification({
      title: 'Введите телефон или email',
      hiddenTimeout: 5000,
    }))
    isValid = false
  }
  return isValid
}

const SubmitRequest = ({ query }) => {
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [searchQuery, setSearchQuery] = useState(query)

  const { isLoading, isSent, submitRequest } = useSubmitRequest()

  const dispatch = useDispatch()

  const submitQuery = useCallback((event) => {
    event.preventDefault()
    const data = { firstName, email, phoneNumber, searchQuery }
    if (validateData(data, dispatch)) {
      submitRequest(data)
    }
  }, [firstName, email, phoneNumber, searchQuery, dispatch])

  return isSent ? (
    <div className={styles.requestSent}> Запрос по &quot;{searchQuery}&quot; отправлен, мы скоро с Вами свяжемся! </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>Отправить запрос по &quot;{searchQuery}&quot;</div>
      <form onSubmit={submitQuery}>
        <div className={styles.fieldContainer}>
          <span>Имя:</span>
          <input
            className={styles.input}
            name="firstName"
            onChange={(event) => setFirstName(event.target.value)}
            type="text"
            value={firstName}
          />
        </div>
        <div className={styles.fieldContainer}>
          <span>Телефон:</span>
          <PhoneNumberInput
            className={userFormStyle.input}
            id="phoneNumber"
            name="phoneNumber"
            onChange={(val) => setPhoneNumber(val)}
            value={phoneNumber}
          />
        </div>
        <div className={styles.fieldContainer}>
          <span>E-mail:</span>
          <input
            className={styles.input}
            name="email"
            onChange={(event) => setEmail(event.target.value)}
            type="email"
            value={email}
          />
        </div>
        <div className={styles.fieldContainer}>
          <span>Вы искали:</span>
          <input
            className={styles.input}
            onChange={(event) => setSearchQuery(event.target.value)}
            type="text"
            value={searchQuery}
          />
        </div>
        <div className={styles.fieldContainer}>
          <button
            className={paymentStyles.submitButton}
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? 'Запрос отправляется...' : 'Отправить запрос' }
          </button>
        </div>
      </form>
    </div>
  )
}

SubmitRequest.propTypes = {
  query: PropTypes.string,
}

export default SubmitRequest
