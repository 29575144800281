import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import OutsideClickHandler from 'sharedComponents/OutsideClickHandler'
import BrandMenu from './BrandMenu'

import style from '../index.module.sass'

const MAX_CATEGORY_DEPTH = 3

class DesktopMenu extends React.Component {
  categorySiblings = (path) => {
    const { categories } = this.props

    if (isEmpty(path)) { return categories }

    return path.reduce((accumulator, categoryId) => (
      accumulator.find(({ id }) => (id === categoryId)).children
    ), categories)
  }

  renderPath() {
    const { currentPath, setPath } = this.props

    const pathTree = currentPath.map((_categoryId, index, array) => (array.slice(0, index + 1)))

    return pathTree.map((categoryPath, index) => (
      <ul key={categoryPath} className={(index === 0) ? style.navL : style.navLv2}>

        {this.categorySiblings(categoryPath.slice(0, categoryPath.length - 1)).map((category) => {
          return (
            <li key={category.id}>
              <a
                className={classNames({
                  [style.active]: category.id === currentPath[index] && category.children.length
                  && index < MAX_CATEGORY_DEPTH - 1,
                  [style.navPar]: category.children.length && index === 0,
                  [style.navPar2]: category.children.length && index !== 0 && index < MAX_CATEGORY_DEPTH - 1,
                })}
                href={category.url}
                onMouseEnter={setPath(category.path)}
              >
                {category.title}
              </a>
            </li>
          )
        })}

      </ul>
    ))
  }

  renderChildren() {
    const { currentPath, setPath } = this.props

    if (currentPath.length === MAX_CATEGORY_DEPTH) { return null }

    return (
      <ul className={style.navLv2}>

        {this.categorySiblings(currentPath).map((category) => {
          return (
            <li key={category.id}>
              <a
                href={category.url}
                onMouseEnter={setPath([...currentPath, category.id])}
              >
                {category.title}
              </a>
            </li>
          )
        })}

      </ul>
    )
  }

  render() {
    const { banner, brands, navHide } = this.props

    return (
      <>
        <div className={style.mask} />

        <OutsideClickHandler
          callback={navHide}
          ignoredClassNames={['show_nav']}
        >
          <div className={style.nav}>

            {this.renderPath()}
            {this.renderChildren()}

            <BrandMenu banner={banner} brands={brands} />

            <div className="clear" />
          </div>
          <div className="clear" />
        </OutsideClickHandler>
      </>
    )
  }
}

const CategoryType = () => {
  return PropTypes.shape({
    childrens: PropTypes.arrayOf(CategoryType),
    id: PropTypes.number,
    instrumentsIcon: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    // eslint-disable-next-line prefer-rest-params
  }).apply(this)
}

DesktopMenu.propTypes = {
  banner: PropTypes.shape({
    backgroundImage: PropTypes.string,
    foreword: PropTypes.string,
    header: PropTypes.string,
    id: PropTypes.number,
    price: PropTypes.string,
    url: PropTypes.string,
  }),
  brands: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    logo: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    // eslint-disable-next-line prefer-rest-params
  })),
  categories: PropTypes.arrayOf(CategoryType),
  currentPath: PropTypes.arrayOf(PropTypes.number),
  setPath: PropTypes.func,
  navHide: PropTypes.func,
}

export default DesktopMenu
