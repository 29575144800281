import { registerGlobalComponent, initialize } from 'common/django'
import store, { provide } from 'common/store'

import ProductsCarousel from 'store/components/ProductsCarousel'
import MainPageBottomBannerSlider from './components/MainPageBottomBannerSlider'
import MainPagePopularCategoriesSlider from './components/MainPagePopularCategoriesSlider'
import MainPageProductsTypesSlider from './components/MainPageProductsTypesSlider'
import MainPageTopSliderBanner from './components/MainPageTopSliderBanner'
import SalesProductsCarousel from './components/SalesProductsCarousel'

import 'store/common'

registerGlobalComponent(provide(MainPageBottomBannerSlider, store), 'MainPageBottomBannerSlider')
registerGlobalComponent(provide(MainPagePopularCategoriesSlider, store), 'MainPagePopularCategoriesSlider')
registerGlobalComponent(provide(MainPageProductsTypesSlider, store), 'MainPageProductsTypesSlider')
registerGlobalComponent(provide(MainPageTopSliderBanner, store), 'MainPageTopSliderBanner')
registerGlobalComponent(provide(ProductsCarousel, store), 'ProductsCarousel')
registerGlobalComponent(provide(SalesProductsCarousel, store), 'SalesProductsCarousel')

initialize({ onDomLoaded: true })
