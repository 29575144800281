import React from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'

import IconWrapper from './IconWrapper'
import styles from './index.module.sass'

const CloseIcon = ({ className, ...wrapperProps }) => (
  <IconWrapper {...wrapperProps}>
    <svg
      className={classNames(styles.icon, { [className]: className })}
      fill="none"
      height="13px"
      viewBox="0 0 13 13"
      width="13px"
      x="0"
      xmlns="http://www.w3.org/2000/svg"
      y="0"
    >
      <path d="M1.00001 1.00001L12 12M12 1L1 12" strokeWidth="2.5" />
    </svg>
  </IconWrapper>
)

CloseIcon.propTypes = {
  className: propTypes.string,
}

CloseIcon.defaultProps = {
  className: '',
}

export default CloseIcon
