const getOffersCount = (state, offersId) => {
  const ordersProducts = state.orders.lastProductsCount

  return offersId.reduce(
    (offers, offerId) => ({
      ...offers,
      [offerId]: ordersProducts[offerId] ?? 0,
    }),
    {},
  )
}

export default getOffersCount
