import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { cancelUnpaidOrder } from 'common/store/auth/actions'

const calculateLeft = (beforeAt) => beforeAt - new Date()

const useTimer = (orderId, beforeAt) => {
  const dispatch = useDispatch()
  const [left, setLeft] = useState(calculateLeft(beforeAt))

  useEffect(
    () => {
      let timer = null

      if (left > 0) {
        timer = setTimeout(
          () => setLeft(calculateLeft(beforeAt)),
          1000,
        )
      } else {
        dispatch(cancelUnpaidOrder(orderId))
      }
      return () => timer && clearTimeout(timer)
    },
    [beforeAt, dispatch, left, orderId, setLeft],
  )

  return left > 0 ? left : 0
}

export default useTimer
