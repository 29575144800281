import { get } from 'lodash'

import { client, getSuccessType } from 'common/api'
import { ACTION_TYPES } from 'common/store/auth/constants'

// eslint-disable-next-line import/prefer-default-export
export const setUserCity = (cityId) => async (dispatch) => {
  const response = await client.fetch(Urls['apiV01:locationsCitiesDetail'](cityId))

  if (response.ok) {
    dispatch({
      type: getSuccessType(ACTION_TYPES.SET_USER_CITY),
      payload: {
        data: {
          city: get(response, 'data'),
        },
      },
    })
  }
}
