import React, { useCallback } from 'react'
import classNames from 'classnames'
import propTypes from 'prop-types'

import { ArrowIcon, CloseIcon } from 'sharedComponents/icons'

import styles from './index.module.sass'

const GoBack = ({
  label,
  onClose,
  goBackCallback,
}) => {
  const handleClose = useCallback(
    (event) => {
      event.preventDefault()
      onClose()
    },
    [onClose],
  )

  const handleReturnToPhoneNumberTyping = useCallback((event) => {
    event.preventDefault()
    goBackCallback()
  }, [])

  return (
    <div className={classNames(styles.header, styles.actionsHeader)}>
      <a
        aria-label="Return"
        className={classNames(styles.link, styles.returnLink)}
        onClick={handleReturnToPhoneNumberTyping}
      >
        <ArrowIcon className={styles.returnIcon} wrapperClassName={styles.returnIconWrapper} />
        <span>
          {label}
        </span>
      </a>
      {onClose ? (
        <a
          aria-label="Close modal"
          className={styles.close}
          onClick={handleClose}
        >
          <CloseIcon className={styles.closeIcon} wrapperClassName={styles.closeIconWrapper} />
        </a>
      ) : null}
    </div>
  )
}

GoBack.propTypes = {
  onClose: propTypes.func,
  label: propTypes.string,
  goBackCallback: propTypes.func,
}

export default GoBack
