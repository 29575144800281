import React from 'react'
import formatDate from 'date-fns/format'
import PropTypes from 'prop-types'

import styles from './index.module.sass'

const OrderedAt = ({ orderedAt }) => (
  <div className={styles.orderedAt}>
    {formatDate(new Date(orderedAt), 'dd.MM.yyyy')}
  </div>
)

OrderedAt.propTypes = {
  orderedAt: PropTypes.string,
}

export default OrderedAt
