import { LOGIN_MODAL_OPEN_TYPES } from 'common/store/auth/actions'

export const getLoginSubTitle = (openType) => {
  switch (openType) {
  case LOGIN_MODAL_OPEN_TYPES.DURING_CHECKOUT:
    return 'для завершения оформления заказа'
  case LOGIN_MODAL_OPEN_TYPES.SIGN_IN:
    return 'или зарегистрироваться'
  case LOGIN_MODAL_OPEN_TYPES.REDIRECT:
  case LOGIN_MODAL_OPEN_TYPES.UPDATE_PHONE_NUMBER:
    return ''
  default:
    throw new Error(`Undefined open type: ${openType}`)
  }
}

export const getLoginTitle = (openType) => {
  switch (openType) {
  case LOGIN_MODAL_OPEN_TYPES.DURING_CHECKOUT:
  case LOGIN_MODAL_OPEN_TYPES.UPDATE_PHONE_NUMBER:
    return 'Подтвердите Ваш номер телефона'
  case LOGIN_MODAL_OPEN_TYPES.SIGN_IN:
    return 'войти в профиль'
  case LOGIN_MODAL_OPEN_TYPES.REDIRECT:
    return 'Для перехода по ссылке войдите в свой профиль'
  default:
    throw new Error(`Undefined open type: ${openType}`)
  }
}
