import { get } from 'lodash'
import {
  combineActions,
  handleActions,
} from 'redux-actions'

import {
  getSuccessType,
  getFailType,
} from 'common/api'
import { getSelfUser, logout } from 'common/store/auth/actions'
import {
  createFavoriteProduct,
  deleteFavoriteProduct,
} from './actions'

const initialState = {
  products: [],
}

const favoriteReducer = handleActions(
  {
    [getSuccessType(getSelfUser)]: (state, action) => ({
      ...state,
      products: get(
        action,
        ['payload', 'favoriteProducts'],
        [],
      ).map(
        (product) => product.product,
      ),
    }),
    [combineActions(
      deleteFavoriteProduct,
      getSuccessType(deleteFavoriteProduct),
      getFailType(createFavoriteProduct),
    )]: (state, { payload }) => ({
      ...state,
      products: get(state, 'products', []).filter((productId) => productId !== payload),
    }),
    [combineActions(
      createFavoriteProduct,
      getSuccessType(createFavoriteProduct),
      getFailType(deleteFavoriteProduct),
    )]: (state, { payload }) => ({
      ...state,
      products: [...new Set(
        [
          ...get(state, 'products', []),
          payload,
        ],
      )],
      [combineActions(
        getSuccessType(logout),
        getFailType(getSelfUser),
      )]: () => initialState,
    }),
  },
  initialState,
)

export default favoriteReducer
