import { isString } from 'lodash'

const PHONE_NUMBER = /^\+7(\d{3})(\d{3})(\d{2})(\d{2})$/

export const isPhoneNumber = (phoneNumber) => PHONE_NUMBER.test(phoneNumber)

export const formatPhoneNumber = (phoneNumber) => (
  isString(phoneNumber) && isPhoneNumber(phoneNumber) ?
    phoneNumber.replace(PHONE_NUMBER, '+7 ($1) $2 $3 $4') :
    phoneNumber
)
