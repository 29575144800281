import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

import propTypes from 'prop-types'

import {
  profileOrdersPath, profilePath, profileReferralPath,
} from 'store/constants'
import OrderPayment from 'store/components/OrderPayment'
import { logout as logoutAction } from 'common/store/auth/actions'

import styles from './index.module.sass'

let menuOverlay = null

const Menu = ({ isOpened, close }) => {
  useEffect(() => {
    if (!menuOverlay) {
      menuOverlay = document.createElement('div')
      document.body.append(menuOverlay)
    }
  }, [])

  const {
    awaitingPaymentOrders,
    endorsedCoupon,
    employees,
    isStaff,
  } = useSelector((state) => ({
    awaitingPaymentOrders: state.auth.awaitingPaymentOrders,
    endorsedCoupon: state.auth.endorsedCoupon,
    employees: state.auth.employees,
    isStaff: state.auth.isStaff,
  }))

  const dispatch = useDispatch()

  const logout = (event) => {
    event.preventDefault()
    dispatch(logoutAction())
  }

  return isOpened ? (
    <>
      {menuOverlay && createPortal(<div className={styles.userMenuOverlay} onClick={close} />, menuOverlay)}
      <ul className={styles.userMenu}>
        {awaitingPaymentOrders.map(
          (order) => (
            <div key={order.id} className={styles.order}>
              <div className={styles.orderContent}>
                <OrderPayment order={order} withNumber />
              </div>
            </div>
          ),
        )}
        {isStaff ? (
          <>
            <li className={styles.topUser0}><a href={Urls['admin:index']()} rel="noreferrer" target="_blank">Админпанель</a></li>
            <li className={styles.topUser2}><a href={Urls['operators:control']()}>Операторская</a></li>
          </>
        ) : null}
        {employees.map(({ partner: { id, organizationName } }) => {
          return (
            <li key={id} className={styles.topUser1}>
              <a href={Urls['partners:control'](id)} >{organizationName}</a>
            </li>
          )
        })}
        <li><a href={profileOrdersPath(Urls)}>Мои заказы</a></li>
        <li><a href={profilePath(Urls)}>Мои данные</a></li>
        {endorsedCoupon ?
          <li><a href={profileReferralPath(Urls)}>Партнёрская программа</a></li>
          : null}
        <li
          className={styles.topUser6}
          data-profile-menu-item="logout"
        >
          <a aria-label="Close" onClick={logout}>Выход</a>
        </li>
      </ul>
    </>
  ) : null
}

Menu.propTypes = {
  isOpened: propTypes.bool,
  close: propTypes.func,
}

export default Menu
