import PropTypes from 'prop-types'

import LocationsPropTypes from './locations'

const User = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  fullName: PropTypes.string,
  phoneNumber: PropTypes.string,
  shortName: PropTypes.string,
})

const UserAddress = PropTypes.shape({
  address: LocationsPropTypes.Address,
})

export default {
  User,
  UserAddress,
}
