import { get, isArray } from 'lodash'

import { client, getFailType, getSuccessType } from 'common/api'
import named from 'common/store/named'
import { showNotification } from 'common/store/notifications/actions'
import { actionTypes } from 'common/store/orders/constants'
import { visitUrl } from 'common/utils/history'
import { purchase } from 'common/ecommerce'

const checkout = (comment) => async (dispatch) => {
  dispatch({
    type: actionTypes.SUBMIT_ORDERS,
  })

  const response = await client.fetch(Urls['api_v01:orders-checkout'](), {
    method: 'POST',
    data: { comment },
  })

  if (response.ok) {
    dispatch({
      type: getSuccessType(actionTypes.SUBMIT_ORDERS),
    })
    const orders = response.data.orders

    await Promise.all(orders.map((order) => purchase(
      order.number,
      order.amount,
      order.products.map((product) => {
        return {
          name: product.offer.product.title,
          id: product.offer.product.id,
          price: product.price,
          quantity: product.count,
          brand: product.offer.product.brand,
          category: product.offer.product.ecommerceCategories,
        }
      }),
      order.delivery && (order.delivery.partnerCost || order.delivery.userCost),
      order.coupon && order.coupon.code,
    )))

    visitUrl(get(response, ['data', 'successUrl'], '/'))
  } else {
    dispatch({
      type: getFailType(actionTypes.SUBMIT_ORDERS),
    })
    const errorMessage = isArray(response.data) ? (
      response.data.join(', ')
    ) : (
      get(response, ['data', 'error'], 'Не удалось оформить заказ')
    )

    dispatch(showNotification({
      title: errorMessage,
      isError: true,
    }))
  }
}

export const submitOrders = named(
  actionTypes.SUBMIT_ORDERS,
  () => (dispatch, getState) => {
    const state = getState()
    const comment = get(state, 'orders.comment')
    dispatch(checkout(comment))
  },
)

export default submitOrders
