import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { flatten } from 'lodash'

import MobileMenu from './components/MobileMenu'
import DesktopMenu from './components/DesktopMenu'

const MOBILE_BREAKPOINT = 760

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPath: Menu.getDeepDownPath(props.categories[0], window.innerWidth < MOBILE_BREAKPOINT),
      opened: false,
      mobile: window.innerWidth < MOBILE_BREAKPOINT,
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.replaceMenuButtons()
  }

  static getDeepDownPath(category, isMobile) {
    if (!isMobile && category && category.children.length > 0) {
      return flatten([category.id, Menu.getDeepDownPath(category.children[0])])
    }

    return category ? [category.id] : []
  }

  updateWindowDimensions() {
    this.setState({ mobile: window.innerWidth < MOBILE_BREAKPOINT })
  }

  componentDidMount() {
    this.listenToMenuButtonsClick()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    this.unlistenToMenuButtonsClick()
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  listenToMenuButtonsClick = () => {
    this.menuButtons.forEach((button) => {
      button.addEventListener('click', this.onMenuToggle)
    })
  }

  unlistenToMenuButtonsClick = () => {
    this.menuButtons.forEach((button) => {
      button.removeEventListener('click', this.onMenuToggle)
    })
  }

  replaceMenuButtons = () => {
    this.menuButton = document.getElementById('menu-button')
    this.mobileMenuButton = document.getElementById('mobile-menu-button')

    this.menuButtons = [this.menuButton, this.mobileMenuButton]

    this.menuButtons.forEach((button) => {
      button.innerHTML = ''
    })
  }

  navToggle = () => {
    this.setState((prevState) => ({
      opened: !prevState.opened,
    }))
  }

  navHide = () => {
    this.setState({ opened: false })
  }

  setPath = (currentPath) => () => {
    this.setState((prevState) => {
      if (!prevState.mobile && currentPath.length === 1 && prevState.currentPath[0] === currentPath[0]) return undefined
      if (!prevState.mobile && currentPath.length === 1) {
        return { currentPath: Menu.getDeepDownPath(this.props.categories
          .find((category) => category.id === currentPath[0])) }
      }
      return { currentPath }
    })
  }

  renderModal() {
    const { currentPath, mobile } = this.state
    const { banner, brands, categories } = this.props
    if (this.state.opened) {
      if (mobile) {
        return (
          <MobileMenu
            categories={categories}
            currentPath={currentPath}
            navHide={this.navHide}
            setPath={this.setPath}
          />
        )
      }

      return (
        <DesktopMenu
          banner={banner}
          brands={brands}
          categories={categories}
          currentPath={currentPath}
          navHide={this.navHide}
          setPath={this.setPath}
        />
      )
    }

    return null
  }

  renderButton() {
    return (
      <span className={`show_nav ${this.state.opened ? 'open' : ''}`} onClick={this.navToggle}>
        Каталог
      </span>
    )
  }

  renderMobileButton() {
    return (
      <span className={`show_nav_mob ${this.state.opened ? 'open' : ''}`} onClick={this.navToggle}>
        Меню
      </span>
    )
  }

  render() {
    return (
      <>
        {ReactDOM.createPortal(this.renderButton(), this.menuButton)}
        {ReactDOM.createPortal(this.renderMobileButton(), this.mobileMenuButton)}

        {this.renderModal()}
      </>
    )
  }
}

const CategoryType = () => {
  return PropTypes.shape({
    childrens: PropTypes.arrayOf(CategoryType),
    id: PropTypes.number,
    instrumentsIcon: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    // eslint-disable-next-line prefer-rest-params
  }).apply(this)
}

Menu.propTypes = {
  banner: PropTypes.shape({
    backgroundImage: PropTypes.string,
    foreword: PropTypes.string,
    header: PropTypes.string,
    id: PropTypes.number,
    price: PropTypes.string,
    url: PropTypes.string,
  }),
  brands: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    logo: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    // eslint-disable-next-line prefer-rest-params
  })),
  categories: PropTypes.arrayOf(CategoryType),
}

export default Menu
