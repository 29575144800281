import PropTypes from 'prop-types'

import DeliveryPropTypes from './deliveries'
import PaymentsPropTypes from './payments'
import UsersPropTypes from './users'
import PartnersPropTypes from './partners'

const LogEntry = PropTypes.shape({
  modified: PropTypes.string,
  actionVerboseName: PropTypes.string,
  modelVerboseName: PropTypes.string,
  user: PropTypes.shape({
    lastName: PropTypes.string,
  }),
  change_message: PropTypes.string,
})

const OrderProduct = PropTypes.shape({
  availableCount: PropTypes.number,
  count: PropTypes.number,
  logEntries: PropTypes.arrayOf(LogEntry),
  originalOfferPrice: PropTypes.string,
  offer: PropTypes.shape({
    id: PropTypes.number,
    product: PropTypes.shape({
      title: PropTypes.string,
    }),
    inStockCount: PropTypes.number,
  }),
})

const Status = PropTypes.shape({
  color: PropTypes.string,
  deliveryStatusType: PropTypes.number,
  name: PropTypes.string,
  paymentStatus: PropTypes.number,
  presentationColor: PropTypes.string,
  presentationLabel: PropTypes.string,
  status: PropTypes.number.isRequired,
})

const StatusHistoryEntry = PropTypes.shape({
  date: PropTypes.string,
  status: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
  }),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
})

const Offer = PropTypes.shape({
  deliveryMinDate: PropTypes.string,
  deliveryMinPrice: PropTypes.string,
  id: PropTypes.number,
  inStockCount: PropTypes.number,
  offeredAt: PropTypes.string,
  oldPrice: PropTypes.number,
  partner: PartnersPropTypes.Partner,
  price: PropTypes.number,
})

const Order = PropTypes.shape({
  allowedCompositeStatuses: PropTypes.arrayOf(Status),
  availableForPayment: PropTypes.bool,
  cancelDate: PropTypes.string,
  canAppendOrderProduct: PropTypes.bool,
  canDeleteOrderProduct: PropTypes.bool,
  compositeStatus: Status,
  delivery: DeliveryPropTypes.OrderDelivery,
  estimatedDeliveryAt: PropTypes.string,
  id: PropTypes.number,
  logEntries: PropTypes.arrayOf(LogEntry),
  number: PropTypes.string,
  operatorComment: PropTypes.string,
  orderedAt: PropTypes.string,
  payment: PaymentsPropTypes.Payment,
  products: PropTypes.arrayOf(OrderProduct),
  productsCost: PropTypes.number,
  totalUserActiveOrders: PropTypes.number,
  user: UsersPropTypes.User,
})

export default { Order, OrderProduct, Status, StatusHistoryEntry, LogEntry, Offer }
