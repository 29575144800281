import React from 'react'
import { ClockIcon } from '@primer/octicons-react'
import PropTypes from 'prop-types'

import Timer from './Timer'

import styles from './index.module.sass'

const PayBefore = ({ orderId, payBefore }) => (
  <div className={styles.payBefore}>
    <ClockIcon size={16} />
    <span className={styles.payBeforeText}>До отмены заказа </span>
    <strong>
      <Timer orderId={orderId} payBefore={new Date(payBefore)} />
    </strong>
  </div>
)

PayBefore.propTypes = {
  orderId: PropTypes.number,
  payBefore: PropTypes.string,
}

export default PayBefore
