import { get } from 'lodash'
import { combineReducers } from 'redux'
import { createMigrate, createTransform, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMerge from 'redux-persist/lib/stateReconciler/autoMergeLevel1'

import authReducer from './auth/reducers'
import cartReducer, { initialState } from './cart/reducers'
import comparisonReducer from './comparison/reducers'
import favoriteReducer from './favorite/reducers'
import locationsReducer from './locations/reducers'
import messagesReducer from './messages/reducers'
import notificationsReducer from './notifications/reducers'
import ordersReducer from './orders/reducers'

const authPersistConfig = {
  key: 'auth',
  stateReconciler: autoMerge,
  storage,
  blacklist: [
    'appendAddressError',
    'appendAddressLoading',
    'authenticateCodeIsSent',
    'authenticateError',
    'authenticateLoading',
    'isLoaded',
    'isLoading',
    'loginIsOpened',
    'openType',
    'sendCodeError',
    'sendCodeLoading',
  ],
}

const migrations = {
  1: (state) => {
    const version = get(state, ['_persist', 'version'], -1)
    return version < 1 ? {} : state
  },
}

const replacer = (key, value) => (value instanceof Date ? value.toISOString() : value)

const reviver = (key, value) => ((typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/))
  ? new Date(value)
  : value)

export const encode = (toDehydrate) => JSON.stringify(toDehydrate, replacer)

export const decode = (toRehydrate) => JSON.parse(toRehydrate, reviver)

const ordersPersistConfig = {
  key: 'orders',
  version: 1,
  stateReconciler: autoMerge,
  storage,
  blacklist: [
    'errors',
    'isLoading',
    'submitInProgress',
    'offersLoading',
  ],
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [createTransform(encode, decode)],
}

const cartMigrations = {
  1: (state) => {
    const version = get(state, ['_persist', 'version'], -1)
    return version < 1 ? initialState : state
  },
}

const persistCartConfig = {
  key: 'cart',
  version: 1,
  stateReconciler: autoMerge,
  storage,
  migrate: createMigrate(cartMigrations, { debug: false }),
  transforms: [createTransform(encode, decode)],
}

const persistAuthReducer = persistReducer(authPersistConfig, authReducer)
const persistCartReducer = persistReducer(persistCartConfig, cartReducer)
const persistOrdersReducer = persistReducer(ordersPersistConfig, ordersReducer)

export default combineReducers({
  auth: persistAuthReducer,
  cart: persistCartReducer,
  comparison: comparisonReducer,
  favorite: favoriteReducer,
  locations: locationsReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  orders: persistOrdersReducer,
})
