import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ title, url }) => (
  <div className="prod_list_t">
    <a href={url} title={title}>
      {title}
    </a>
  </div>
)

Title.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
}

export default Title
