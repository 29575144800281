import React from 'react'
import PropTypes from 'prop-types'

import PicturePropTypes from 'shared-prop-types/picture'

const createMedia = (media) => {
  const parts = [
    media.maxWidth ? `(max-width: ${media.maxWidth}px)` : null,
    media.minWidth ? `(min-width: ${media.minWidth}px)` : null,
  ]

  return parts.filter(
    (part) => part,
  ).join(' and ')
}

const createSrcSet = (srcSet) => srcSet.map((src) => `${src.url} ${src.scaleSize}x`).join(', ')

const Picture = ({ alt, picture: data }) => (
  <picture>
    {data.sources.map(
      (sourceData) => (
        <source
          key={sourceData.src}
          media={createMedia(sourceData.media)}
          src={sourceData.src}
          srcSet={createSrcSet(sourceData.srcSet)}
        />
      ),
    )}
    <img alt={alt} src={data.src} srcSet={createSrcSet(data.srcSet)} />
  </picture>
)

Picture.propTypes = {
  alt: PropTypes.string,
  picture: PicturePropTypes.Picture,
}

export default Picture
