import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMerge from 'redux-persist/lib/stateReconciler/autoMergeLevel1'

import reducers from './reducers'

const persistConfig = {
  key: 'root',
  stateReconciler: autoMerge,
  storage,
  blacklist: [
    'auth',
    'locations',
    'notifications',
    'orders',
  ],
}

const rootReducer = persistReducer(persistConfig, reducers)

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(
    thunk,
  ),
))

persistStore(store)

export default store
