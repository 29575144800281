import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const GLOBALLY_IGNORED_CLASS_NAMES = ['ReactModalPortal']

function useOutsideClickHandler(ref, callback, ignoredClassNames = []) {
  useEffect(() => {
    function handleClickOutside(event) {
      const ignoredClassNamesArr = [...GLOBALLY_IGNORED_CLASS_NAMES, ...ignoredClassNames]
      const ignoredElements = []
      ignoredClassNamesArr.forEach((className) => {
        ignoredElements.push(...Array.from(document.getElementsByClassName(className)))
      })
      if (ignoredElements.some((node) => (
        node.contains(event.target)))) { return }
      if (ref.current && !ref.current.contains(event.target)) { callback() }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
}

const OutsideClickHandler = ({ callback, children, ignoredClassNames }) => {
  const wrapperRef = useRef(null)

  useOutsideClickHandler(wrapperRef, callback, ignoredClassNames)

  return <div ref={wrapperRef} style={{ width: '100%' }}>{children}</div>
}

OutsideClickHandler.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.node,
  ignoredClassNames: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
}

export default OutsideClickHandler
