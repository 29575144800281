import { get } from 'lodash'

import { client, getFailType, getSuccessType } from 'common/api'
import { showNotification } from 'common/store/notifications/actions'
import { getWordForm } from 'common/utils/text'
import named from '../named'

const showRelatedProductCreateMessageSuccess = (fieldName, productsCount, title) => showNotification({
  title: fieldName === 'comparisonProducts' ? `Товар ${title} добавлен к сравнению` : `Товар ${title} добавлен в избранное`,
  subTitle: `Всего в списке ${productsCount} ${getWordForm(productsCount, ['товар', 'товара', 'товаров'])}`,
  action: {
    title: fieldName === 'comparisonProducts' ? 'Сравнить' : 'Перейти',
    url: fieldName === 'comparisonProducts' ? Urls['catalog:product-comparison']() : Urls['catalog:product-favorite'](),
  },
})

const showRelatedProductCreateMessageFailed = (fieldName, productsCount, title) => showNotification({
  title: fieldName === 'comparisonProducts' ? `Не удалось добавить товар ${title} к сравнению` : `Не удалось добавить товар ${title} в избранное`,
  subTitle: `Всего в списке ${productsCount} ${getWordForm(productsCount, ['товар', 'товара', 'товаров'])}`,
  action: {
    title: fieldName === 'comparisonProducts' ? 'Сравнить' : 'Перейти',
    url: fieldName === 'comparisonProducts' ? Urls['catalog:product-comparison']() : Urls['catalog:product-favorite'](),
  },
  isError: true,
})

const createUserRelatedProduct = (
  actionType,
  urlName,
  productId,
  localStatePath,
  payloadFieldName,
  title,
) => async (dispatch, getState) => {
  const url = Urls[urlName]()

  const state = getState()
  const productsCount = get(state, [...localStatePath, 'length'], 0)

  dispatch({
    type: actionType,
    payload: productId,
  })

  const response = await client.fetch(
    url,
    {
      method: 'POST',
      data: {
        product: productId,
      },
    },
  )

  if (response.ok) {
    dispatch({
      type: getSuccessType(actionType),
      payload: response.data.product,
    })
    dispatch(showRelatedProductCreateMessageSuccess(payloadFieldName, productsCount + 1, title))
  } else {
    dispatch({
      type: getFailType(actionType),
      payload: productId,
    })
    dispatch(showRelatedProductCreateMessageFailed(payloadFieldName, productsCount, title))
  }
}

export const createRelatedProductCreator = (
  actionType,
  urlName,
  localStatePath,
  payloadFieldName,
) => named(
  actionType,
  (productId, title = '') => async (dispatch) => {
    dispatch(createUserRelatedProduct(
      actionType,
      urlName,
      productId,
      localStatePath,
      payloadFieldName,
      title,
    ))
  },
)

export const showRelatedProductDeleteMessageSuccess = (fieldName, productsCount, title) => showNotification({
  title: fieldName === 'comparisonProducts' ? `Товар ${title} удален из сравнения` : `Товар ${title} удален из избранного`,
  subTitle: `Всего в списке ${productsCount} ${getWordForm(productsCount, ['товар', 'товара', 'товаров'])}`,
  action: {
    title: fieldName === 'comparisonProducts' ? 'Сравнить' : 'Перейти',
    url: fieldName === 'comparisonProducts' ? Urls['catalog:product-comparison']() : Urls['catalog:product-favorite'](),
  },
})

export const showRelatedProductDeleteMessageFailed = (fieldName, productsCount, title) => showNotification({
  title: fieldName === 'comparisonProducts' ? `Не удалось удалить товар ${title} из сравнения` : `Не удалось удалить товар ${title} из избранного`,
  subTitle: `Всего в списке ${productsCount} ${getWordForm(productsCount, ['товар', 'товара', 'товаров'])}`,
  action: {
    title: fieldName === 'comparisonProducts' ? 'Сравнить' : 'Перейти',
    url: fieldName === 'comparisonProducts' ? Urls['catalog:product-comparison']() : Urls['catalog:product-favorite'](),
  },
  isError: true,
})

const deleteUserRelatedProduct = (
  actionType,
  urlName,
  productId,
  localStatePath,
  payloadFieldName,
  title,
) => async (dispatch, getState) => {
  const url = Urls[urlName](productId)

  const state = getState()
  const productsCount = get(state, [...localStatePath, 'length'], 0)

  dispatch({
    type: actionType,
    payload: productId,
  })

  const response = await client.fetch(
    url,
    {
      method: 'DELETE',
    },
  )

  if (response.ok || response.status === 404) {
    dispatch({
      type: getSuccessType(actionType),
      payload: productId,
    })
    dispatch(showRelatedProductDeleteMessageSuccess(payloadFieldName, productsCount - 1, title))
  } else {
    dispatch({
      type: getFailType(actionType),
      payload: productId,
    })
    dispatch(showRelatedProductDeleteMessageFailed(payloadFieldName, productsCount, title))
  }
}

export const createRelatedProductDestroyer = (
  actionType,
  urlName,
  localStatePath,
  payloadFieldName,
) => named(
  actionType,
  (productId, title = '') => async (dispatch) => {
    dispatch(deleteUserRelatedProduct(
      actionType,
      urlName,
      productId,
      localStatePath,
      payloadFieldName,
      title,
    ))
  },
)
