import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import { BREAKPOINTS } from 'common/utils/breakpoints'
import style from './index.module.sass'

class MainPageBottomBannerSlider extends React.Component {
  settings = {
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: BREAKPOINTS.SMALLDESKTOP,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: BREAKPOINTS.TABLET,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: BREAKPOINTS.MOBILE,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  }

  render() {
    const { slides } = this.props

    return (
      <div className="slider">
        <Slider {...this.settings}>
          {slides.map((slide, index) => {
            return (
              <div
                key={slide.id}
                className={(index === 1) ? style.sliderContainerCenter : style.sliderContainer}
              >
                <div className="home_prods_slide">
                  <div
                    className={`home_prod ${(index === 1) ? style.sliderCenter : style.slider}`}
                  >
                    <a
                      className={style.slide}
                      href={slide.url}
                    >
                      <img
                        alt="product"
                        src={slide.backgroundImage.url}
                        srcSet={`${slide.backgroundImage.url2X} 2x`}
                      />
                      {/* eslint-disable-next-line react/no-danger */}
                      <span className="home_prod_t">
                        {slide.foreword ? (<div>{slide.foreword}</div>) : null}
                        <span>
                          {slide.header ? <div dangerouslySetInnerHTML={{ __html: slide.header }} /> : null}
                        </span>
                      </span>
                      <span className="home_prod_p">{slide.price} <span className="cur">₽</span></span>
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}

MainPageBottomBannerSlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({
    backgroundImage: PropTypes.shape({
      url: PropTypes.string,
      url2X: PropTypes.string,
    }),
    foreword: PropTypes.string,
    header: PropTypes.string,
    id: PropTypes.number,
    price: PropTypes.string,
    url: PropTypes.string,
  })),
}

export default MainPageBottomBannerSlider
