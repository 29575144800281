import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import hasProfileNotifications from 'common/store/auth/selectors/has-profile-notifications'

import styles from './index.module.sass'

const ProfileIcon = ({ isOpened, onToggle }) => {
  const {
    firstName,
    hasNotifications,
    isAuthenticated,
  } = useSelector((state) => ({
    firstName: state.auth.firstName,
    hasNotifications: hasProfileNotifications(state),
    isAuthenticated: state.auth.isAuthenticated,
  }))

  const authName = useMemo(() => firstName || 'Профиль', [firstName])

  const handleToggle = useCallback(
    (event) => {
      event.preventDefault()
      onToggle()
    },
    [onToggle],
  )

  return (
    <a
      className={classNames({
        open: isAuthenticated && isOpened,
        is_authenticated: isAuthenticated,
      })}
      onClick={handleToggle}
      role={isAuthenticated ? 'Open login modal' : 'Open profile menu'}
    >
      {isAuthenticated ? authName : 'Войти'}
      {hasNotifications ? <span className={styles.notificationIcon} /> : null}
    </a>
  )
}

ProfileIcon.propTypes = {
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func,
}

export default ProfileIcon
