import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import useOrderPayment from '../../hooks/use-order-payment'

import styles from './index.module.sass'

const GoToPaymentButton = ({ paymentId, isSmall }) => {
  const { goToPayment, isLoading } = useOrderPayment(paymentId)

  return (
    <button
      className={classNames(
        styles.goToPaymentButton,
        {
          [styles.small]: isSmall,
        },
      )}
      disabled={isLoading}
      onClick={goToPayment}
      type="button"
    >
      Оплатить
    </button>
  )
}

GoToPaymentButton.defaultProps = {
  isSmall: false,
}

GoToPaymentButton.propTypes = {
  paymentId: PropTypes.number,
  isSmall: PropTypes.bool,
}

export default GoToPaymentButton
