import React from 'react'
import PropTypes from 'prop-types'

import style from '../index.module.sass'

const BrandMenu = ({ banner, brands }) => {
  return (
    <div className={style.navRr}>
      <div className={style.navProd}>
        <a href={banner.url}>
          <img alt="product" className={style.bannerImg} src={banner.backgroundImage} />
          <span className={style.navProdT}>
            {banner.foreword ? banner.foreword : null}
            <span>
              {banner.header ? <div dangerouslySetInnerHTML={{ __html: banner.header }} /> : null}
            </span>
          </span>
          {(banner.price) ? (
            <span className={style.navProdP}>{banner.price}<span className="cur"> ₽</span></span>) : null}
        </a>
      </div>
      {brands.map(((brand) => (
        <div key={brand.id} className={style.navBrand}>
          <a aria-label="to brand" href={brand.url}>
            <img alt={brand.title} src={brand.logo} />
          </a>
        </div>
      )
      ))}
    </div>
  )
}

BrandMenu.propTypes = {
  banner: PropTypes.shape({
    backgroundImage: PropTypes.string,
    foreword: PropTypes.string,
    header: PropTypes.string,
    id: PropTypes.number,
    price: PropTypes.string,
    url: PropTypes.string,
  }),
  brands: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    logo: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    // eslint-disable-next-line prefer-rest-params
  })),
}

export default BrandMenu
