import React, { useCallback, useMemo } from 'react'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'

import useMedia, { DEVICES } from 'common/hooks/use-media'

import style from './index.module.sass'

const Pagination = ({ count, limit, offset, onChange }) => {
  const page = useMemo(() => Math.floor(offset / limit), [limit, offset])
  const pagesCount = useMemo(() => Math.ceil(count / limit), [count, limit])

  const device = useMedia()
  const pageRangeDisplayed = device === DEVICES.MOBILE ? 3 : 7

  const handlePageChange = useCallback(
    ({ selected }) => {
      if (onChange) {
        const newOffset = selected * limit

        onChange({ offset: newOffset, limit })
      } else {
        window.location.href = `${window.location.pathname}?page=${selected + 1}`
      }
    },
    [onChange, limit],
  )

  return pagesCount > 1 ? (
    <ReactPaginate
      activeClassName={style.active}
      breakClassName={style.dots}
      breakLabel="..."
      containerClassName={style.navContainer}
      disableInitialCallback
      forcePage={page}
      initialPage={page}
      marginPagesDisplayed={1}
      nextClassName={style.hide}
      onPageChange={handlePageChange}
      pageCount={pagesCount}
      pageRangeDisplayed={pageRangeDisplayed}
      previousClassName={style.hide}
    />
  ) : null
}

Pagination.propTypes = {
  count: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  onChange: PropTypes.func,
}

export default Pagination
