import { useEffect, useState } from 'react'
import { isEqual } from 'lodash'

export default (callback, dependencies) => {
  const [prevDependencies, setPrevDependencies] = useState(dependencies)

  useEffect(() => {
    if (!isEqual(prevDependencies, dependencies)) {
      callback(...prevDependencies)
      setPrevDependencies(dependencies)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
