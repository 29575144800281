import PropTypes from 'prop-types'

import PartnersPropTypes from './partners'
import UsersPropTypes from './users'

const Receipt = PropTypes.shape({
  file: PropTypes.string,
  createdAt: PropTypes.string,
})

const TransportCompany = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  ordersCount: PropTypes.number,
})

const DeliveryMethod = PropTypes.shape({
  id: PropTypes.number,
  ordersCount: PropTypes.number,
  title: PropTypes.string,
  transportCompany: PropTypes.number,
  type: PropTypes.number,
})

const OrderDeliveryMethod = PropTypes.shape({
  id: PropTypes.number,
  ordersCount: PropTypes.number,
  title: PropTypes.string,
  transportCompany: TransportCompany,
  type: PropTypes.number,
})

const PickupPoint = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
})

const Delivery = PropTypes.shape({
  deliveryMethod: DeliveryMethod,
  pickupPoint: PickupPoint,
  store: PartnersPropTypes.Store,
  userAddress: UsersPropTypes.UserAddress,
})

const OrderDelivery = PropTypes.shape({
  cityName: PropTypes.string,
  allowToCreateReceipts: PropTypes.bool,
  deliveryMethod: OrderDeliveryMethod,
  pickupPoint: PickupPoint,
  postalCode: PropTypes.string,
  receipts: PropTypes.arrayOf(Receipt),
  store: PartnersPropTypes.Store,
  trackingUrl: PropTypes.string,
  userAddress: UsersPropTypes.UserAddress,
})

const MassPickup = PropTypes.shape({
  cost: PropTypes.number,
  endWaitingAt: PropTypes.string,
  externalId: PropTypes.string,
  height: PropTypes.number,
  id: PropTypes.number,
  intakeId: PropTypes.string,
  length: PropTypes.number,
  registeredAt: PropTypes.string,
  startWaitingAt: PropTypes.string,
  userCreated: UsersPropTypes.User,
  userRegistered: UsersPropTypes.User,
  volume: PropTypes.number,
  waitingDate: PropTypes.string,
  weight: PropTypes.number,
  width: PropTypes.number,
})

const Shipment = PropTypes.shape({
  id: PropTypes.number,
  shippingDate: PropTypes.string,
  user: UsersPropTypes.User,
  deliveries: PropTypes.arrayOf(Delivery),
})

export default {
  Delivery,
  OrderDelivery,
  DeliveryMethod,
  MassPickup,
  Shipment,
  PickupPoint,
  TransportCompany,
}
