import { handleActions } from 'redux-actions'

import { captureMessages, close } from './actions'

export const initialState = {
  messages: [],
}

const messagesReducer = handleActions(
  {
    [captureMessages]: (state, action) => ({
      ...state,
      messages: [...state.messages, ...action.payload],
    }),
    [close]: (state) => ({
      ...state,
      messages: [],
    }),
  },
  initialState,
)

export default messagesReducer
