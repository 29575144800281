import { createAction } from 'redux-actions'

import { client, getSuccessType, getFailType } from 'common/api'
import { ACTION_TYPES } from './constants'
import named from '../named'

export const setCartState = createAction(ACTION_TYPES.SET_CART_STATE)
export const setOrderUserData = createAction(ACTION_TYPES.SET_ORDER_USER_DATA)

export const getCartUserData = named(
  ACTION_TYPES.GET_CART_USER_DATA,
  () => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_CART_USER_DATA,
    })

    const response = await client.fetch(
      Urls['apiV01:cartUserData'](),
    )

    if (response.ok) {
      dispatch({
        type: getSuccessType(ACTION_TYPES.GET_CART_USER_DATA),
        payload: response.data,
      })
    } else {
      dispatch({
        type: getFailType(ACTION_TYPES.GET_CART_USER_DATA),
      })
    }
  },
)

export const getCartStatus = named(
  ACTION_TYPES.GET_CART_STATUS,
  () => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_CART_STATUS,
    })

    const response = await client.fetch(
      Urls['apiV01:cartStatus'](),
    )

    if (response.ok) {
      dispatch({
        type: getSuccessType(ACTION_TYPES.GET_CART_STATUS),
        payload: response.data.status,
      })
    } else {
      dispatch({
        type: getFailType(ACTION_TYPES.GET_CART_STATUS),
      })
    }
  },
)
