import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import { useSelector, useDispatch } from 'react-redux'

import { showNotification, hideNotification } from 'common/store/notifications/actions'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Notification from './components/Notification'

import styles from './index.module.sass'

const NOTIFICATIONS_COOKIES = 'notifications'
const NOTIFICATIONS_LIMIT = 2

const Notifications = () => {
  const { notifications } = useSelector(
    (state) => ({
      notifications: state.notifications.notifications,
    }),
  )
  const dispatch = useDispatch()

  notifications.forEach((notification) => {
    toast(
      <Notification
        action={notification.action}
        isError={notification.isError}
        subTitle={notification.subTitle}
        title={notification.title}
      />,
      {
        onClose: dispatch(hideNotification(notification.id)),
        className: styles.notificationContainer,
        progressStyle: { background: 'white' },
        ...(notification.hiddenTimeout && { autoClose: notification.hiddenTimeout }),
      },
    )
  })
  useEffect(() => {
    const serializedNotifications = Cookies.get(NOTIFICATIONS_COOKIES)

    if (serializedNotifications) {
      const storedNotifications = JSON.parse(decodeURIComponent(serializedNotifications))

      storedNotifications.forEach((notification) => {
        dispatch(
          showNotification({
            title: notification.title,
          }),
        )
      })
    }

    Cookies.remove(NOTIFICATIONS_COOKIES)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ToastContainer limit={NOTIFICATIONS_LIMIT} />
  )
}

export default Notifications
