import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import propTypes from 'prop-types'
import { debounce, countBy, memoize } from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  getCurrentCity as getCurrentCityAction,
  getCities as getCitiesAction,
  updateSelectCityModalOpenState,
  closeSelectCityModal,
} from 'common/store/locations/actions'
import {
  setUserCity as setUserCityAction,
} from 'common/store/auth/actions'
import { getCityId } from 'store/utils/cityNameId'
import Modal from 'sharedComponents/Modal'
import GoBack from 'sharedComponents/GoBack'
import ChooseCity from './components/ChooseCity'
import EnterCity from './components/EnterCity'
import RenderCity from './components/city'

import style from './index.module.sass'

class CitySelect extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      cityInputValue: '',
      manualMode: false,
    }
    this.replaceCitySelectButtons()
  }

  static getDerivedStateFromProps(props) {
    const {
      getCurrentCity,
      userCity,
      userIsLoaded,
      userIsLoading,
      updateIsLoading,
    } = props

    if (!userCity && userIsLoaded && !userIsLoading && !updateIsLoading) {
      getCurrentCity()
    }

    return null
  }

  componentDidMount() {
    this.listenToCitySelectButtonsClick()

    const {
      getCities,
    } = this.props

    getCities()
  }

  componentWillUnmount() {
    this.unlistenToCitySelectButtonsClick()
  }

  listenToCitySelectButtonsClick = () => {
    this.citySelectButtons.forEach((button) => {
      button.addEventListener('click', this.onCitySelectToggle)
    })
  }

  unlistenToCitySelectButtonsClick = () => {
    this.citySelectButtons.forEach((button) => {
      button.removeEventListener('click', this.onCitySelectToggle)
    })
  }

  onCitySelectToggle = () => this.props.updateOpenState(!this.props.isOpen)

  close = () => this.props.close()

  selectCity = (cityId) => {
    const { setUserCity } = this.props

    setUserCity(cityId)

    this.onCitySelectToggle()
  }

  onChange = (event) => {
    const { target } = event
    const { value } = target
    this.setState({
      cityInputValue: value,
    })

    const { getCities } = this.props
    getCities(value)
  }

  replaceCitySelectButtons = () => {
    this.citySelectButtons = Array.from(document.getElementsByClassName('citySelect-container'))

    this.citySelectButtons.forEach((button) => {
      button.innerHTML = ''
    })
  }

  renderButton(cityId, cityNameDisplay) {
    return (
      <span
        className={classNames(style.showCity, { [style.open]: this.props.isOpen })}
        data-current-city-id={cityId}
      >
        <span>{cityNameDisplay}</span>
      </span>
    )
  }

  getDuplicatesCities = memoize((cities) => {
    const cityNameOccurrences = countBy(cities, (city) => city.name)
    return Object.keys(cityNameOccurrences).filter((key) => (cityNameOccurrences[key] > 1))
  })

  getDuplicatesCitiesRegions = memoize((cities) => {
    const cityNameOccurrences = countBy(cities, (city) => `${city.name} ${city.region.fullName}`)
    return Object.keys(cityNameOccurrences).filter((key) => (cityNameOccurrences[key] > 1))
  })

  renderModal(cityNameDisplay) {
    const { cityInputValue } = this.state
    const { cities, userCity } = this.props

    if (!this.props.isOpen) { return null }

    const duplicatesCities = this.getDuplicatesCities(cities)
    const duplicatesCitiesRegions = this.getDuplicatesCitiesRegions(cities)

    const citiesDiv = cities.map((city) => {
      return (
        <li
          key={city.id}
          className={classNames({ [style.topCityB]: city.isPrimary })}
          data-city-id={city.id}
          onClick={() => this.selectCity(city.id)}
          role="menuitem"
        >
          <a>
            {duplicatesCities.includes(city.name) ? (
              <>
                {city.type ? (<>{city.type}.</>) : null} {city.name}
                <span className={style.topCityRegion}>
                  {city.region.fullName}
                  {duplicatesCitiesRegions.includes(`${city.name} ${city.region.fullName}`) ? (
                    <span>{city.district ? `, ${city.district.fullName},` : '' }</span>
                  ) : null}
                </span>
              </>
            ) : city.name}
          </a>
        </li>
      )
    })

    return (
      <Modal
        className={style.citySelect}
        onRequestClose={this.close}
        scrollToTop
        verticalCenteredDesktop
        wrapped={false}
      >
        {this.state.manualMode ? (
          <>
            <GoBack
              goBackCallback={() => this.setState({ manualMode: false })}
              label="Вернуться к выбору города"
              onClose={this.close}
            />
            <EnterCity
              close={this.close}
            />
          </>
        ) : (
          <ChooseCity
            citiesDiv={citiesDiv}
            cityInputValue={cityInputValue}
            cityNameDisplay={cityNameDisplay}
            onChange={this.onChange}
            switchToManualMode={() => this.setState({ manualMode: true })}
            userCity={userCity}
          />
        )}
      </Modal>
    )
  }

  render() {
    const { userCity, cityName, postalCode } = this.props
    const cityId = getCityId(userCity, postalCode)
    const cityNameDisplay = RenderCity(cityName, postalCode, userCity)

    return (
      <>
        {this.citySelectButtons.map(
          (button) => ReactDOM.createPortal(this.renderButton(cityId, cityNameDisplay), button),
        )}
        {this.renderModal(cityNameDisplay)}
      </>
    )
  }
}

CitySelect.propTypes = {
  close: propTypes.func,
  cities: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string,
      is_primary: propTypes.bool,
    }),
  ),
  getCurrentCity: propTypes.func,
  getCities: propTypes.func,
  isOpen: propTypes.bool,
  setUserCity: propTypes.func,
  updateIsLoading: propTypes.func,
  updateOpenState: propTypes.func,
  userCity: propTypes.shape({
    id: propTypes.number,
    name: propTypes.string,
  }),
  cityName: propTypes.string,
  postalCode: propTypes.string,
  userIsLoaded: propTypes.bool,
  userIsLoading: propTypes.bool,
}

CitySelect.defaultProps = {
  cities: [],
  citiesLoading: false,
}

const mapStateToProps = (state) => ({
  cities: state.locations.cities,
  citiesLoading: state.locations.citiesLoading,
  isOpen: state.locations.citiesSelectIsOpen,
  updateIsLoading: state.auth.updateIsLoading,
  userCity: state.auth.city,
  cityName: state.auth.cityName,
  postalCode: state.auth.postalCode,
  userIsLoaded: state.auth.isLoaded,
  userIsLoading: state.auth.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  close: bindActionCreators(closeSelectCityModal, dispatch),
  getCities: debounce(bindActionCreators(getCitiesAction, dispatch), 700),
  getCurrentCity: bindActionCreators(getCurrentCityAction, dispatch),
  setUserCity: bindActionCreators(setUserCityAction, dispatch),
  updateOpenState: bindActionCreators(updateSelectCityModalOpenState, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CitySelect)
