import React from 'react'
import PropTypes from 'prop-types'

import { getWordForm } from 'common/utils/text'
import useTimer from '../../hooks/use-timer'

import styles from './index.module.sass'

const getDays = (left) => Math.floor(left / (1000 * 60 * 60 * 24))
const getHours = (left) => Math.floor(left / (1000 * 60 * 60))
const getMinutes = (hours, left) => Math.floor((left / (1000 * 60))) - (hours * 60)
const getSeconds = (hours, minutes, left) => Math.floor(left / 1000) - (hours * 60 * 60) - (minutes * 60)

const formatLeft = (left) => {
  const hours = getHours(left)
  const minutes = getMinutes(hours, left)
  const seconds = getSeconds(hours, minutes, left)

  const formattedHours = `${hours}`.padStart(2, '0')
  const formattedMinutes = `${minutes}`.padStart(2, '0')
  const formattedSeconds = `${seconds}`.padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

const formatLeftDays = (leftDays) => `${leftDays} ${getWordForm(leftDays, ['день', 'дня', 'дней'])}`

const Timer = ({ orderId, payBefore }) => {
  const left = useTimer(orderId, payBefore)
  const leftDays = getDays(left)

  return leftDays > 0 ? formatLeftDays(leftDays) : <span className={styles.timer}>{formatLeft(left)}</span>
}

Timer.propTypes = {
  orderId: PropTypes.number,
  payBefore: PropTypes.instanceOf(Date),
}

export default Timer
