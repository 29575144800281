import React from 'react'
import PropTypes from 'prop-types'

import ProductCard from 'store/components/ProductCard'
import Carousel from './components/Carousel'

const ProductsCarousel = ({ products, isLoading }) => (
  <Carousel >
    {products.map(
      (product) => <ProductCard key={product.id} isLoading={isLoading} {...product} />,
    )}
  </Carousel>
)

ProductsCarousel.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    actualOfferId: PropTypes.number,
    biggestOldPrice: PropTypes.number,
    id: PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string })),
    smallestPrice: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
  })),
  isLoading: PropTypes.bool,
}

export default ProductsCarousel
