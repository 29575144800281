import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { differenceInSeconds } from 'date-fns'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import VerificationInput from 'react-verification-input'

import useYM from 'common/hooks/use-ym'
import { GOALS } from 'common/ym'
import { formatPhoneNumber } from 'common/utils/formatters/phone'
import {
  authenticate,
  sendAuthenticationCode,
  updatePhoneNumber,
  LOGIN_MODAL_OPEN_TYPES, returnToPhoneNumberTyping,
} from 'common/store/auth/actions'
import GoBack from 'sharedComponents/GoBack'

import styles from './index.module.sass'

const getWaitTime = (resendingTime) => differenceInSeconds(resendingTime, new Date())

const LabelText = ({ phoneNumber }) => (
  <>
    Код выслан на номер <span className={styles.phoneNumber}>{formatPhoneNumber(phoneNumber)}</span>
  </>
)

LabelText.propTypes = {
  phoneNumber: propTypes.string,
}

const getConfirmSmsCodeButtonClassName = (openType) => {
  switch (openType) {
  case LOGIN_MODAL_OPEN_TYPES.UPDATE_PHONE_NUMBER:
  case LOGIN_MODAL_OPEN_TYPES.DURING_CHECKOUT:
  case LOGIN_MODAL_OPEN_TYPES.REDIRECT:
    return 'confirm_number'
  case LOGIN_MODAL_OPEN_TYPES.SIGN_IN:
    return 'to_get_the_code'
  default:
    throw new Error(`Undefined open type: ${openType}`)
  }
}

const getGoal = (openType) => {
  switch (openType) {
  case LOGIN_MODAL_OPEN_TYPES.DURING_CHECKOUT:
    return GOALS.CONFIRM_SMS_CODE_LOGIN_GOAL_ID
  case LOGIN_MODAL_OPEN_TYPES.REDIRECT:
  case LOGIN_MODAL_OPEN_TYPES.SIGN_IN:
    return GOALS.CONFIRM_SMS_CODE_CHECKOUT_GOAL_ID
  case LOGIN_MODAL_OPEN_TYPES.UPDATE_PHONE_NUMBER:
    return undefined
  default:
    throw new Error(`Undefined open type: ${openType}`)
  }
}

const {
  AUTH_TYPES,
} = window.DJANGO_CONSTANTS

const CodeForm = ({
  onClose,
}) => {
  const dispatch = useDispatch()
  const {
    error,
    loading,
    openType,
    phoneNumber,
    resendingTime,
    sendCodeLoading,
  } = useSelector(
    (state) => ({
      error: state.auth.authenticateError,
      loading: state.auth.authenticateLoading,
      openType: state.auth.openType,
      phoneNumber: state.auth.temporaryPhoneNumber,
      resendingTime: state.auth.resendingTime,
      sendCodeLoading: state.auth.sendCodeLoading,
    }),
  )

  const [code, setCode] = useState('')
  const [waitTime, setWaitTime] = useState(resendingTime ? getWaitTime(resendingTime) : 0)
  const reachGoal = useYM(getGoal(openType))

  useEffect(() => {
    const internal = resendingTime ? setInterval(() => {
      const waitInSeconds = getWaitTime(resendingTime)
      setWaitTime(waitInSeconds)

      if (waitInSeconds <= 0) {
        clearInterval(internal)
      }
    }, 1000) : undefined

    return () => internal && clearInterval(internal)
  }, [resendingTime])

  const onChange = useCallback((val) => {
    setCode(val)
  }, [setCode])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()

    switch (openType) {
    case LOGIN_MODAL_OPEN_TYPES.SIGN_IN:
    case LOGIN_MODAL_OPEN_TYPES.REDIRECT:
    case LOGIN_MODAL_OPEN_TYPES.DURING_CHECKOUT:
      dispatch(authenticate({ phoneNumber, code }, AUTH_TYPES.PHONE_NUMBER))
      break
    case LOGIN_MODAL_OPEN_TYPES.UPDATE_PHONE_NUMBER:
      dispatch(updatePhoneNumber(phoneNumber, code))
      break
    default:
      throw new Error(`Undefined open type: ${openType}`)
    }
    reachGoal()
  }, [code, dispatch, openType, phoneNumber, reachGoal])

  const resendCode = useCallback(
    (event) => {
      event.preventDefault()
      if (!sendCodeLoading) {
        dispatch(sendAuthenticationCode(phoneNumber))
      }
    },
    [dispatch, phoneNumber, sendCodeLoading],
  )

  const handleReturnToPhoneNumberTyping = useCallback(() => {
    dispatch(returnToPhoneNumberTyping())
  }, [dispatch])

  return (
    <form className={styles.signIn} id="login-form-code" onSubmit={handleSubmit}>
      <GoBack
        goBackCallback={handleReturnToPhoneNumberTyping}
        label="Вернуться к вводу номера"
        onClose={onClose}
      />
      <div className={styles.content}>
        <div className={styles.middleContainer}>
          <div className={classNames(styles.title, styles.titleWithOutSubTitle)}>введите код</div>
          <div>
            <label
              className={classNames(styles.label, { [styles.error]: error })}
              htmlFor="phoneNumber"
            >
              {error || <LabelText phoneNumber={phoneNumber} />}
            </label>
            <div className={styles.verificationInput}>
              <VerificationInput
                autoFocus
                classNames={{
                  container: styles.inputContainer,
                  character: styles.inputBlock,
                  characterInactive: styles.inactive,
                  characterSelected: styles.active,
                }}
                id="code"
                inputProps={
                  { type: 'tel' }
                }
                length={4}
                name="code"
                onChange={onChange}
                removeDefaultStyles
                validChars="0-9"
                value={code}
              />
            </div>
          </div>
          <button
            className={classNames(styles.button, getConfirmSmsCodeButtonClassName(openType))}
            disabled={loading}
            type="submit"
          >
            Подтвердить
          </button>
        </div>

        <div className={classNames(styles.bottomContainer, styles.smallDescription)}>
          {waitTime <= 0 ? (
            <a className={classNames(styles.link, styles.dottedLink)} onClick={resendCode}>Повторно выслать код</a>
          ) : `Повторная отправка доступна через ${waitTime}`}
        </div>
      </div>
    </form>
  )
}

CodeForm.propTypes = {
  onClose: propTypes.func,
}

export default CodeForm
