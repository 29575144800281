import { createAction } from 'redux-actions'
import { v4 as uuidv4 } from 'uuid'

import {
  ACTION_TYPES,
} from './constants'
import named from '../named'

export const hideNotification = createAction(ACTION_TYPES.HIDE_NOTIFICATION)

/**
 * @param  {{
 *   title: {string} Заголовок,
 *   subTitle: {string} Подзаголовок,
 *   isAutoHidden: {bool} Скрыть автоматически, не дожидаясь нажатия на кнопку закрыть,
 *   hiddenTimeout: {number} Если используется автоматическое скрытие, то параметр задает,
 *                           через сколько мс будет скрыто уведомление. По умолчанию 10000 мс
 * }} notification - Диаметр окружности.
 */
export const showNotification = named(
  ACTION_TYPES.SHOW_NOTIFICATION,
  (notification) => (dispatch) => {
    const id = uuidv4()

    dispatch({
      type: ACTION_TYPES.SHOW_NOTIFICATION,
      payload: {
        id,
        ...notification,
      },
    })
  },
)
