import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'

import { COUPON_COOKIES } from 'store/constants'
import { showNotification } from 'common/store/notifications/actions'
import { useDispatch } from 'react-redux'

const CouponUrlHandler = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const queryParams = new URLSearchParams(location.search)

  if (queryParams.has(COUPON_COOKIES)) {
    const coupon = queryParams.get(COUPON_COOKIES)
    queryParams.delete(COUPON_COOKIES)
    Cookies.set(COUPON_COOKIES, coupon)

    dispatch(showNotification({ title: `Промокод ${coupon} добавлен в корзину` }))

    return (
      <Redirect to={`${location.pathname}?${queryParams.toString()}`} />
    )
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: '<!-- Need to be rendered on every site page -->' }} />
  )
}

export default CouponUrlHandler
