import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { parse } from 'querystring'
import { useSelector } from 'react-redux'

import usePrevDependencies from 'common/hooks/use-prev-dependencies'
import useSearchProducts from 'common/hooks/use-search-products'
import OutsideClickHandler from 'sharedComponents/OutsideClickHandler'
import Loader from 'store/components/Loader'
import FoundProducts from './components/FoundProducts'
import SearchHistory from './components/SearchHistory'

import style from './index.module.sass'

const Search = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const {
    clearSearchHistory,
    createSearchHistoryItem,
    foundBrands,
    foundProducts,
    getSearchHistory,
    isLoading,
    performSearch,
    searchHistory,
  } = useSearchProducts()
  const [isTyping, setTyping] = useState(false)
  const [opened, setOpened] = useState(false)
  const [query, setQuery] = useState('')

  useEffect(() => {
    const searchUrlParam = get(
      parse(window.location.search.replace('?', '')),
      'search',
      '',
    )
    if (searchUrlParam && query !== searchUrlParam) setQuery(searchUrlParam)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQuery])

  useEffect(() => {
    if (isAuthenticated) {
      getSearchHistory()
    }
  }, [getSearchHistory, isAuthenticated])

  useEffect(() => {
    performSearch(query)
  }, [performSearch, query])

  useEffect(() => {
    if (query.trim().length > 0 && !isTyping) {
      setTyping(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setTyping])

  usePrevDependencies((prevLoading) => {
    if (!isLoading && prevLoading) {
      setTyping(false)
    }
  }, [isLoading])

  const handleClear = (e) => {
    e.preventDefault()
    clearSearchHistory()
  }

  const handleSearchHistoryClick = async (searchHistoryItem) => {
    setQuery(searchHistoryItem)
    performSearch(searchHistoryItem)
  }

  return (
    <>
      {opened && <div className={style.mask} />}
      <div className={`top_search ${opened ? 'open' : ''}`}>
        <OutsideClickHandler callback={() => { setOpened(false) }}>
          <div className="search">
            <form action={Urls['catalog:index']()} className={style.form} method="get">
              <input
                autoComplete="off"
                className="search_inp ym-record-keys"
                name="search"
                onChange={(e) => setQuery(e.target.value)}
                onFocus={() => setOpened(true)}
                placeholder="Поиск по сайту"
                type="text"
                value={query}
              />
              {isLoading ? (
                <div className={style.loadingContainer}>
                  <Loader
                    className={style.loading}
                    containerClassName={style.loadingPreloader}
                  />
                </div>
              ) : <input className="search_sub ym-record-keys" type="submit" value="" />}
            </form>
            {opened && !query && !!searchHistory.length && (
              <SearchHistory
                onClearClick={handleClear}
                onSearchHistoryItemClick={handleSearchHistoryClick}
                searchHistory={searchHistory}
              />
            )}
            {opened && query && !isTyping && !isLoading && (
              <FoundProducts
                createSearchHistoryItem={createSearchHistoryItem}
                foundBrands={foundBrands}
                foundProducts={foundProducts}
                query={query}
              />
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

export default Search
