import initSentry from 'common/sentry'
import { registerGlobalComponent } from 'common/django'
import store, { provide } from 'common/store'

import 'styles/store/style.scss'

import withRouter from 'common/router/withRouter'
import Notifications from 'sharedComponents/Notifications'
import Pagination from 'sharedComponents/Pagination'
import CartMenuItem from 'store/components/CartMenuItem'
import CitySelect from 'store/components/CitySelect'
import FooterMenu from 'store/components/FooterMenu'
import Menu from 'store/components/Menu'
import ProfileMenu from 'store/components/ProfileMenu'
import Search from 'store/components/Search'
import CouponUrlHandler from 'store/components/CouponUrlHandler'
import RedirectToOrdersOnLogin from 'store/components/RedirectToOrdersOnLogin'

initSentry()

registerGlobalComponent(provide(CartMenuItem, store), 'CartMenuItem')
registerGlobalComponent(provide(CitySelect, store), 'CitySelect')
registerGlobalComponent(provide(withRouter(CouponUrlHandler), store), 'CouponUrlHandler')
registerGlobalComponent(provide(FooterMenu, store), 'FooterMenu')
registerGlobalComponent(provide(Menu, store), 'Menu')
registerGlobalComponent(provide(Notifications, store), 'Notifications')
registerGlobalComponent(Pagination, 'Pagination')
registerGlobalComponent(provide(ProfileMenu, store), 'ProfileMenu')
registerGlobalComponent(provide(Search, store), 'Search')
registerGlobalComponent(provide(RedirectToOrdersOnLogin, store), 'RedirectToOrdersOnLogin')
