import { debounce } from 'lodash'

import { actionTypes } from 'common/store/orders/constants'
import { client, getSuccessType, getFailType } from 'common/api'
import { showNotification } from 'common/store/notifications/actions'
import named from 'common/store/named'
import { setCartState } from 'common/store/cart/actions'
import { CART_FIRST_STEP } from 'common/store/cart/constants'
import { addToCart } from 'common/ecommerce'

const DEBOUNCE_TIMEOUT = 1000

const updateActions = {}
const createActions = {}

const createOrderProductCreator = (dispatch) => async (offer, count = 1, withMessage = true) => {
  const response = await client.fetch(
    Urls['apiV01:cartProductsList'](),
    {
      method: 'POST',
      data: {
        offer,
        count,
      },
      cancellableRequestId: `POST::ORDER_PRODUCTS_${offer}`,
    },
  )

  showResponseMessages(dispatch, response)

  if (response.ok) {
    dispatch({
      type: getSuccessType(actionTypes.CREATE_ORDER_PRODUCT),
      payload: response.data.data,
    })

    if (withMessage) {
      dispatch(showNotification({
        title: 'Товар добавлен в корзину',
        action: {
          url: '/orders/new/',
          title: 'Перейти в корзину',
        },
        hiddenTimeout: 5000,
      }))
    }
    dispatch(setCartState({
      cartState: CART_FIRST_STEP,
    }))
    commerceAppendToCartFromResponse(response)
  } else {
    dispatch(showNotification({
      title: 'Не удалось добавить товар в корзину',
      isError: true,
    }))
    dispatch({
      type: getFailType(actionTypes.CREATE_ORDER_PRODUCT),
      payload: {
        offer,
        errors: response?.data?.errors || {},
      },
    })
  }
}

const createAction = (key, registry, creator) => {
  registry[key] = debounce(
    creator(),
    DEBOUNCE_TIMEOUT,
  )

  return registry[key]
}

const getOrCreateAction = (key, registry, creator) => registry[key] || createAction(key, registry, creator)

export const createOrderProduct = named(
  actionTypes.CREATE_ORDER_PRODUCT,
  (offer, count = 1, withMessage = true) => async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_ORDER_PRODUCT,
      payload: {
        offer,
        count,
      },
    })

    const action = getOrCreateAction(offer, createActions, () => createOrderProductCreator(dispatch))
    action(offer, count, withMessage)
  },
)

const updateOrderProductCountCreator = (dispatch) => async (offer, count) => {
  const url = Urls['apiV01:cartProductsDetail'](offer)

  const response = await client.fetch(
    url,
    {
      method: 'PATCH',
      data: {
        count,
      },
      cancellableRequestId: `PATCH::${url}`,
    },
  )

  showResponseMessages(dispatch, response)

  if (response.ok) {
    dispatch({
      type: getSuccessType(actionTypes.UPDATE_ORDER_PRODUCT_COUNT),
      payload: response.data.data,
    })
    commerceAppendToCartFromResponse(response)
  } else {
    dispatch(showNotification({
      title: 'Не удалось изменить количество товара в корзине',
      isError: true,
    }))
    dispatch({
      type: getFailType(actionTypes.UPDATE_ORDER_PRODUCT_COUNT),
      payload: {
        offer,
        errors: response?.data?.errors || {},
      },
    })
  }
}

export const updateOrderProductCount = named(
  actionTypes.UPDATE_ORDER_PRODUCT_COUNT,
  (offer, count) => async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_ORDER_PRODUCT_COUNT,
      payload: {
        offer,
        count,
      },
    })

    const action = getOrCreateAction(offer, updateActions, () => updateOrderProductCountCreator(dispatch))
    action(offer, count)
  },
)

export const deleteOrderProduct = named(
  actionTypes.DELETE_ORDER_PRODUCT,
  (offer) => async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_ORDER_PRODUCT,
      payload: offer,
    })

    const response = await client.fetch(
      Urls['apiV01:cartProductsDetail'](offer),
      {
        method: 'DELETE',
      },
    )

    showResponseMessages(dispatch, response)

    if (response.ok) {
      dispatch({
        type: getSuccessType(actionTypes.DELETE_ORDER_PRODUCT),
        payload: offer,
      })
    } else {
      dispatch(showNotification({
        title: 'Не удалось удалить товар из корзины',
        isError: true,
      }))
      dispatch({
        type: getFailType(actionTypes.DELETE_ORDER_PRODUCT),
        payload: {
          offer,
          errors: response?.errors || {},
        },
      })
    }
  },
)

const showResponseMessages = (dispatch, response) => (response?.data?.messages || []).forEach(
  (message) => (
    dispatch(showNotification({
      title: message,
    }))
  ),
)

const commerceAppendToCartFromResponse = (response) => (
  addToCart(
    response.data.data.offer.product.title,
    response.data.data.offer.product.id,
    response.data.data.offer.price,
    response.data.data.offer.product.brand,
    response.data.data.offer.product.ecommerceCategories,
    response.data.data.count,
  )
)
