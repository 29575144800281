import { get } from 'lodash'
import { client, getFailType, getSuccessType } from 'common/api'
import {
  ACTION_TYPES,
} from './constants'
import {
  createRelatedProductCreator,
  createRelatedProductDestroyer,
  showRelatedProductDeleteMessageSuccess,
  showRelatedProductDeleteMessageFailed,
} from '../helpers/related-products'
import named from '../named'

export const createComparisonProduct = createRelatedProductCreator(
  ACTION_TYPES.CREATE_COMPARISON_PRODUCT,
  'apiV01:comparisonProductsList',
  ['comparison', 'products'],
  'comparisonProducts',
)

export const deleteComparisonProduct = createRelatedProductDestroyer(
  ACTION_TYPES.DELETE_COMPARISON_PRODUCT,
  'apiV01:comparisonProductsDetail',
  ['comparison', 'products'],
  'comparisonProducts',
)

export const deleteComparisonCategory = named(
  ACTION_TYPES.DELETE_COMPARISON_CATEGORY,
  (categoryId, products) => async (dispatch, getState) => {
    dispatch({
      type: ACTION_TYPES.DELETE_COMPARISON_CATEGORY,
      payload: categoryId,
    })

    const response = await client.fetch(
      Urls['apiV01:comparisonProductsCategory'](categoryId),
      {
        method: 'DELETE',
      },
    )

    if (response.ok) {
      dispatch({
        type: getSuccessType(ACTION_TYPES.DELETE_COMPARISON_CATEGORY),
        payload: response.data,
      })
      const productsCount = get(response, 'length', 0)
      products.forEach(
        (product) => dispatch(showRelatedProductDeleteMessageSuccess(
          'comparisonProducts',
          productsCount,
          product.title,
        )),
      )
    } else {
      dispatch({
        type: getFailType(ACTION_TYPES.DELETE_COMPARISON_CATEGORY),
        payload: categoryId,
      })
      const state = getState()
      const productsCount = get(state, 'length', 0)
      products.forEach(
        (product) => dispatch(showRelatedProductDeleteMessageFailed(
          'comparisonProducts',
          productsCount,
          product.title,
        )),
      )
    }
  },
)
