import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import usePrevDependencies from 'common/hooks/use-prev-dependencies'
import Login from 'store/components/Login'
import Modal from 'sharedComponents/Modal'
import { closeLogin } from 'common/store/auth/actions'

import styles from './index.module.sass'

const LoginModal = () => {
  const dispatch = useDispatch()
  const {
    isAuthenticated,
    isOpened,
  } = useSelector(
    (state) => ({
      isAuthenticated: state.auth.isAuthenticated,
      isOpened: state.auth.loginIsOpened,
    }),
  )

  const handleClose = useCallback(
    () => dispatch(closeLogin()),
    [dispatch],
  )

  usePrevDependencies(
    (prevIsAuthenticated) => {
      if (!prevIsAuthenticated && isAuthenticated) {
        handleClose()
      }
    },
    [isAuthenticated],
  )

  return isOpened ? (
    <Modal
      onRequestClose={handleClose}
      wrapped
    >
      <div className={styles.signIn} >
        <Login onClose={handleClose} />
      </div>
    </Modal>
  ) : null
}

export default LoginModal
