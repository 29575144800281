import { createAction } from 'redux-actions'

import { client, getSuccessType } from 'common/api'
import { ACTION_TYPES } from 'common/store/auth/constants'

import { showNotification } from 'common/store/notifications/actions'
import named from 'common/store/named'
import { closeLogin } from './login-modal'

export const getAppendUserAddress = () => Urls['apiV01:userAddressesList']()

export const updateUserTelegramAccount = (telegramLoginData) => async (dispatch) => {
  const response = await client.fetch(Urls['apiV01:userSelfUpdateTelegramAccount'](), {
    method: 'PUT',
    data: {
      telegramLoginData,
    },
  })

  if (response.ok) {
    dispatch({
      type: getSuccessType(ACTION_TYPES.SET_USER_TELEGRAM_ACCOUNT),
      payload: response,
    })
  } else {
    dispatch(
      showNotification({
        title: 'Не удалось привязать аккаунт Telegram',
        isError: true,
      }),
    )
  }
}

export const updatePhoneNumber = named(
  ACTION_TYPES.UPDATE_PHONE_NUMBER,
  (phoneNumber, code) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_PHONE_NUMBER,
    })

    const url = Urls['apiV01:userSelfPhoneNumber']()

    const response = await client.fetch(url, {
      method: 'PUT',
      data: {
        phoneNumber,
        code,
      },
    })

    if (response.ok) {
      const data = response.data
      dispatch({
        type: getSuccessType(ACTION_TYPES.UPDATE_PHONE_NUMBER),
        payload: response.data,
      })
      dispatch(closeLogin())
      dispatch(
        showNotification({
          title: `Номер изменен на ${data.phoneNumber}`,
        }),
      )
    } else {
      dispatch({
        type: getSuccessType(ACTION_TYPES.UPDATE_PHONE_NUMBER),
        payload: response.data,
      })
      dispatch(
        showNotification({
          title: 'Не удалось изменить номер телефона',
          isError: true,
        }),
      )
    }
  },
)

export const cancelUnpaidOrder = createAction(ACTION_TYPES.CANCEL_UNPAID_ORDER)
