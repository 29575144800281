import { createAction } from 'redux-actions'

import {
  actionTypes,
} from 'common/store/orders/constants'

export {
  createOrderProduct,
  updateOrderProductCount,
  deleteOrderProduct,
} from './order-products'
export { default as submitOrders } from './submit-orders'
export { updateOrdersComment } from './orders-comment'
export const updateOrderProducts = createAction(actionTypes.UPDATE_ORDER_PRODUCTS)
