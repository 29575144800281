import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import useActualOffer from 'common/store/orders/hooks/use-actual-offer'
import Price from './Price'
import AppendToCart from './AppendToCart'
import AppendOneToCart from './AppendOneToCart'

const Offer = ({
  id,
  offers,
  orderedAt,
  showCart,
}) => {
  const {
    actualOffer,
    actualOfferCount,
    appendToCart,
    count,
    inStockCount,
    oldPrice,
    price,
    discount,
    isLoading,
    updateProductCount,
  } = useActualOffer(offers)

  const handleAppendToCart = useCallback(
    () => {
      if (count > 0) {
        updateProductCount(actualOffer?.id, actualOfferCount + 1)
      } else {
        appendToCart()
      }
    },
    [count, updateProductCount, actualOffer?.id, actualOfferCount, appendToCart],
  )

  const allowedForAppend = count < inStockCount

  return (
    <>
      {discount ? <div className="discount">-{discount}%</div> : null}
      {!count || orderedAt ? (
        <Price
          biggestOldPrice={oldPrice}
          orderedAt={orderedAt}
          smallestPrice={price}
        />
      ) : null}
      {(inStockCount || null) && (!count ? (
        <AppendToCart
          appendToCart={handleAppendToCart}
          id={id}
          orderedAt={orderedAt}
        />
      ) : (
        <AppendOneToCart
          allowedForAppend={allowedForAppend}
          appendToCart={handleAppendToCart}
          count={count}
          isLoading={isLoading}
          orderedAt={orderedAt}
          showCart={showCart}
        />
      ))}
    </>
  )
}

Offer.defaultProps = {
  showCart: true,
}

Offer.propTypes = {
  id: PropTypes.number,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      inStockCount: PropTypes.number,
    }),
  ),
  orderedAt: PropTypes.string,
  showCart: PropTypes.bool,
}

export default Offer
