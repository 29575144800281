import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showNotification } from 'common/store/notifications/actions'

import { client } from 'common/api'
import { captureErrorMessage } from 'common/sentry'

const ERROR_MESSAGE = 'Не удалось перейти к оплате. Пожалуйста, свяжитесь с оператором через чат.'

const useOrderPayment = (paymentId) => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const goToPayment = useCallback(
    async () => {
      const url = Urls['apiV01:paymentsUrl'](paymentId)

      setLoading(true)

      const response = await client.fetch(url, {
        method: 'PUT',
        cancellableRequestId: `CREATE-PAYMENT-${paymentId}`,
      })

      setLoading(false)

      if (response.ok) {
        window.location.href = response.data.paymentUrl
      } else {
        captureErrorMessage(`${ERROR_MESSAGE} Оплата: ${paymentId}`)
        dispatch(
          showNotification({
            title: ERROR_MESSAGE,
            isError: true,
          }),
        )
      }
    },
    [dispatch, paymentId, setLoading],
  )

  return {
    isLoading,
    goToPayment,
  }
}

export default useOrderPayment
