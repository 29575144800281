import React from 'react'
import ReactDOM from 'react-dom'
import { get, partial } from 'lodash'

import { transformResponseBody } from 'common/api/utils/transformations'

const store = {}
const mountedReactNodes = []

// Errors handlers

function raiseError(message) {
  if (!(process.env.NODE_ENV === 'production')) {
    throw new Error(message)
  }
}

// Components store
function appendComponentToStore(component, name) {
  if (!store[name]) {
    store[name] = component
  } else if (!(process.env.NODE_ENV === 'production')) {
    raiseError(`Component with name: ${name} already exists on store?`)
  }
}

function getComponentFromStore(componentName) {
  const Component = store[componentName]

  if (!Component) {
    raiseError(`Not find component with name ${componentName}`)
  }

  return Component
}

// Register components
export function registerGlobalComponent(component, name) {
  const componentName = name || get(component, 'name')

  if (componentName) {
    appendComponentToStore(component, componentName)
  } else {
    raiseError(`Not find component name for component ${component}`)
  }
}

// Mount components
const getNodeComponentName = (node) => {
  const componentName = get(node, ['dataset', 'reactComponentName'])

  if (!componentName) {
    raiseError(`Not find component name for node ${node} with id ${node.id}`)
  }

  return componentName
}

function mountComponent(node) {
  const componentName = getNodeComponentName(node)
  const Component = getComponentFromStore(componentName)
  const props = JSON.parse(node.getAttributeNode('data-react-component-props').value)
  const transformedProps = transformResponseBody(props)

  ReactDOM.render(<Component {...transformedProps} />, node)
}

function initializeNode(reinitializeComponents, node) {
  const id = node.id
  if ((!reinitializeComponents && !mountedReactNodes.includes(id)) || reinitializeComponents) {
    mountComponent(node)
  }
}

function mountComponents(reinitializeComponents = false) {
  const nodes = document.querySelectorAll('[data-react-component-name]')
  nodes.forEach(partial(initializeNode, reinitializeComponents))
}

const DEFAULT_OPTIONS = {
  onDomLoaded: false,
  reinitializeComponents: false,
}

export function initialize(options = DEFAULT_OPTIONS) {
  const initializeOptions = { ...DEFAULT_OPTIONS, ...options }

  const mount = partial(mountComponents, initializeOptions.reinitializeComponents)
  if (initializeOptions.onDomLoaded) {
    document.addEventListener('DOMContentLoaded', mount)
  } else {
    mount()
  }
}
