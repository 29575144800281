import { get } from 'lodash'

import RenderCity from 'store/components/CitySelect/components/city'

export default (state) => {
  const userCity = get(state, ['auth', 'city'])
  const cityName = get(state, ['auth', 'cityName'])
  const postalCode = get(state, ['auth', 'postalCode'])
  return RenderCity(cityName, postalCode, userCity)
}
