export const getWordForm = (num, word) => {
  const cases = [2, 0, 1, 1, 1, 2]

  return word[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]]
}

export const getAccusativeWordForm = (num, word) => (isAccusativeSingularForm(num) ? word[0] : word[1])

const isAccusativeSingularForm = (number) => {
  const numberStr = number.toString()
  const isLastNumberOne = numberStr[numberStr.length - 1] === '1'
  const elevenInTheEnd = numberStr.length > 1 && numberStr.substr(numberStr.length - 2) === '11'
  return isLastNumberOne && !elevenInTheEnd
}

// родительный падеж. words = ["товар", "товара", "товаров"]
export const getGenitiveWordForm = (num, words) => words[getGenetiveIndex(num)]

const getGenetiveIndex = (number) => {
  const numberStr = number.toString()

  if (numberStr.length > 1) {
    const lastTwoNumbers = parseInt(numberStr.substr(numberStr.length - 2), 10)
    const many = [11, 12, 13, 14]
    if (many.includes(lastTwoNumbers)) {
      return 2
    }
  }

  const lastNumber = parseInt(numberStr.substr(numberStr.length - 1), 10)
  if (lastNumber === 1) return 0

  if (lastNumber > 1 && lastNumber < 5) {
    return 1
  }
  return 2
}
