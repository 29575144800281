import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.sass'

const AppendOneToCart = ({
  allowedForAppend,
  appendToCart,
  count,
  isLoading,
  orderedAt,
  showCart,
}) => {
  return (
    <div className={classNames(styles.inCart, {
      [styles.inCartOrdered]: orderedAt,
      [styles.inCartSmall]: !showCart,
    })}
    >
      <div
        className={classNames(styles.inCartCountContainer, {
          [styles.inCartCountLoading]: isLoading,
          [styles.withoutAppend]: !allowedForAppend,
        })}
      >
        {isLoading && !allowedForAppend ? (
          <div className={styles.appendToCartLoadingContainer}>
            <span className={styles.appendToCartLoading} />
          </div>
        ) : null}
        <a
          className={styles.inCartCount}
          href={!isLoading ? Urls['orders:new']() : undefined}
        >
          <span>
            {showCart ? (
              <span className={classNames({
                [styles.inCartCountHideOnMobile]: allowedForAppend,
              })}
              >
                В корзине&nbsp;
              </span>
            ) : null}
            {count} шт.
          </span>
        </a>
      </div>
      {allowedForAppend ? (
        <div
          className={styles.appendOneToCartContainer}
        >
          {isLoading ? (
            <div className={styles.appendToCartLoadingContainer}>
              <span className={styles.appendToCartLoading} />
            </div>
          ) : null}
          <a
            className={classNames(
              styles.appendOneToCart,
            )}
            onClick={appendToCart}
            role="button"
          >+1
          </a>
        </div>
      ) : null}
    </div>
  )
}

AppendOneToCart.defaultProps = {
  showCart: true,
}

AppendOneToCart.propTypes = {
  allowedForAppend: PropTypes.bool,
  appendToCart: PropTypes.func,
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  orderedAt: PropTypes.string,
  showCart: PropTypes.bool,
}

export default AppendOneToCart
