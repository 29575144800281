export const CONTENT_TYPES = {
  JSON: 'application/json',
  MULTIPART_FORM_DATA: 'multipart/form-data',
}

export const HEADERS = {
  CONTENT_TYPE: 'Content-Type',
}

const CUSTOM_HEADERS_NORMALIZE = {
  'content-md5': 'Content-MD5',
  dnt: 'DNT',
  etag: 'ETag',
  'last-event-id': 'Last-Event-ID',
  tcn: 'TCN',
  te: 'TE',
  'www-authenticate': 'WWW-Authenticate',
  'x-dnsprefetch-control': 'X-DNSPrefetch-Control',
}

const normalizeHeaderName = (headerName) => headerName
  .split('-')
  .map((text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase())
  .join('-')

const normalizeHeader = (header) => {
  const lowerHeader = header.toLowerCase()
  return CUSTOM_HEADERS_NORMALIZE[lowerHeader] || normalizeHeaderName(header)
}

export const normalizeHeaders = (headers) => Object.keys(headers)
  .reduce((normalizedHeaders, header) => ({
    ...normalizedHeaders,
    [normalizeHeader(header)]: headers[header],
  }), {})

export const filterHeaders = (headers) => Object.keys(headers)
  .reduce((filteredHeaders, header) => ({
    ...filteredHeaders,
    ...(headers[header] ? { [header]: headers[header] } : {}),
  }), {})

export const DEFAULT_HEADERS = {
  Accept: CONTENT_TYPES.JSON,
  'Content-Type': CONTENT_TYPES.JSON,
}
