import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { client } from 'common/api'
import { showNotification } from 'common/store/notifications/actions'

export default () => {
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const submitRequest = useCallback(async (data) => {
    setLoading(true)
    const userTime = new Date().toTimeString()
    const response = await client.fetch(Urls['apiV01:submitRequest'](), {
      method: 'POST',
      data: {
        ...data,
        userTime,
      },
    })
    if (response.ok) {
      setIsSent(true)
    } else {
      dispatch(showNotification({
        title: 'Ошибка отправки запроса',
        hiddenTimeout: 3000,
      }))
    }
    setLoading(false)
  }, [dispatch])
  return {
    submitRequest,
    isLoading,
    isSent,
  }
}
