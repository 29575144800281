import Cookies from 'js-cookie'

import named from 'common/store/named'
import { ACTION_TYPES, SESSION_COOKIE_KEY } from 'common/store/auth/constants'
import { client, getFailType, getSuccessType } from 'common/api'

const getSelfUser = named(
  ACTION_TYPES.GET_SELF_USER,
  () => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.GET_SELF_USER })

    const response = await client.fetch(Urls['apiV01:userSelfList']())

    dispatch({
      type: response.ok ? getSuccessType(ACTION_TYPES.GET_SELF_USER) : getFailType(ACTION_TYPES.GET_SELF_USER),
      payload: response.data,
    })

    if (!response.ok) {
      Cookies.remove(SESSION_COOKIE_KEY)
    }
  },
)

export default getSelfUser
