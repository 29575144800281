import React from 'react'
import PropTypes from 'prop-types'

const Image = ({
  image,
  title,
  url,
}) => {
  const {
    productCardDesktopImage1X,
    productCardDesktopImage2X,
    productCardDesktopImage3X,
    productCardMobileImage1X,
    productCardMobileImage2X,
    productCardMobileImage3X,
  } = image || {}

  return (
    <div className="prod_list_img">
      <a href={url} title={title}>
        {image ? (
          <picture>
            <source
              media="(min-width: 760px)"
              srcSet={`
                ${productCardDesktopImage1X} 1x,
                ${productCardDesktopImage2X} 2x,
                ${productCardDesktopImage3X} 3x,
              `}
            />
            <img
              alt={title}
              loading="lazy"
              src={productCardMobileImage1X}
              srcSet={`
                ${productCardMobileImage1X} 1x,
                ${productCardMobileImage2X} 2x,
                ${productCardMobileImage3X} 3x,
              `}
            />
          </picture>
        ) : (
          <div className="img_placeholder-relative">
            <div className="img_placeholder__content">
              <span className="img_placeholder__text">
                Нет фото
              </span>
            </div>
          </div>
        )}
      </a>
    </div>
  )
}

Image.propTypes = {
  image: PropTypes.shape({
    productCardDesktopImage1x: PropTypes.string,
    productCardDesktopImage2x: PropTypes.string,
    productCardDesktopImage3x: PropTypes.string,
    productCardMobileImage1x: PropTypes.string,
    productCardMobileImage2x: PropTypes.string,
    productCardMobileImage3x: PropTypes.string,
  }),
  title: PropTypes.string,
  url: PropTypes.string,
}

export default Image
