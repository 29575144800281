import React from 'react'
import { Provider } from 'react-redux'
import hoistStatics from 'hoist-non-react-statics'

function provide(Component, store) {
  const ConnectedComponent = (props) => (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  )

  return hoistStatics(ConnectedComponent, Component)
}

export default provide
