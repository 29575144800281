import React, { useCallback } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  createFavoriteProduct,
  deleteFavoriteProduct,
} from 'common/store/favorite/actions'

const Favorite = ({ id, title }) => {
  const { favoriteProducts } = useSelector((state) => ({
    favoriteProducts: state.favorite.products,
  }))
  const dispatch = useDispatch()

  const inFavorites = favoriteProducts.includes(id)

  const handleLike = useCallback((event) => {
    event.preventDefault()

    if (inFavorites) {
      dispatch(deleteFavoriteProduct(id, title))
    } else {
      dispatch(createFavoriteProduct(id, title))
    }
  }, [dispatch, id, inFavorites, title])

  return (
    <a
      className={
        classNames(
          'prod_list_like',
          {
            active: inFavorites,
          },
        )
      }
      href="#"
      onClick={handleLike}
      role="button"
    >
      Понравилось
    </a>
  )
}

Favorite.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
}

export default Favorite
