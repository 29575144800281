import { debounce } from 'lodash'
import { client, getSuccessType, getFailType } from 'common/api'
import { actionTypes, BRANDS_COUNT, SEARCH_DEBOUNCE_WAIT } from './constants'

export const createSearchHistoryItem = async (dispatch, productName) => {
  const response = await client.fetch('search-history/', {
    method: 'POST',
    data: { productName },
  })
  if (response.ok) {
    dispatch({
      type: getSuccessType(actionTypes.CREATE_SEARCH_HISTORY_ITEM),
    })
  }
}

export const getSearchHistory = async (dispatch) => {
  const response = await client.fetch('search-history/')

  if (response.ok) {
    dispatch({
      type: getSuccessType(actionTypes.GET_SEARCH_HISTORY),
      payload: response.data.history,
    })
  }
}

export const clearSearchHistory = async (dispatch) => {
  const response = await client.fetch('search-history/clear', { method: 'POST' })

  if (response.ok) {
    dispatch({ type: getSuccessType(actionTypes.CLEAR_SEARCH_HISTORY) })
  }
}

export const performSearch = async (dispatch, query) => {
  dispatch({
    type: actionTypes.PERFORM_SEARCH,
  })

  const url = Urls['apiV01:productsList']()

  const encodedQuery = encodeURIComponent(query)

  const searchParams = `search=${encodedQuery}&limit=12&brands_limit=${BRANDS_COUNT}`
  const response = await client.fetch(`${url}?${searchParams}`, {
    cancellableRequestId: `GET::PRODUCTS_SEARCH::${url}`,
  })

  if (response.ok) {
    dispatch({
      type: getSuccessType(actionTypes.PERFORM_SEARCH),
      payload: response.data,
    })
  } else {
    dispatch({
      type: getFailType(actionTypes.PERFORM_SEARCH),
    })
  }
}

export const debouncedPerformSearch = debounce(performSearch, SEARCH_DEBOUNCE_WAIT)
