import { getClientId, getClientIdFromCookie, ymIsInitialized } from 'common/ym'

export default class YM {
  #clientID

  constructor() {
    this.updateClientID()
  }

  updateClientID = () => {
    if (ymIsInitialized()) {
      getClientId((clientId) => {
        this.#clientID = clientId
      })
    } else {
      this.#clientID = getClientIdFromCookie()
    }
  }

  setupOnce(addGlobalEventProcessor, getCurrentHub) {
    addGlobalEventProcessor((event, hint) => {
      const self = getCurrentHub().getIntegration(YM)
      if (!self) {
        return event
      }
      return self.enhanceEventWithYMClientId(event, hint)
    })

    if (!this.#clientID) {
      this.updateClientID()
    }
  }

  enhanceEventWithYMClientId = async (event) => {
    if (!this.#clientID) {
      this.updateClientID()
    }

    return ({
      ...event,
      contexts: {
        ...event.contexts,
        ym: {
          clientID: this.#clientID,
        },
      },
    })
  }
}
