import React, { useState } from 'react'
import classNames from 'classnames'

const FooterMenu = () => {
  const TABS = {
    CUSTOMERS: 'customers',
    PARTNERS: 'partners',
    COMPANY: 'company',
  }

  const [activeTab, setActiveTab] = useState(TABS.CUSTOMERS)

  return (
    <>
      <nav className={classNames('nav_bot', {
        open: activeTab === TABS.CUSTOMERS,
      })}
      >
        <div className="bot_t" onClick={() => { setActiveTab(TABS.CUSTOMERS) }}>Покупателям</div>
        <ul>
          <li><a href="/how-to-make-an-order/">Оформление заказа</a></li>
          <li><a href="/payment/">Оплата</a></li>
          <li><a href="/delivery/">Доставка</a></li>
          <li><a href="/refund/">Возврат</a></li>
          <li><a href="/privacy-policy/">Политика конфиденциальности</a></li>
          <li><a href="/public-offer">Публичная оферта</a></li>
        </ul>
      </nav>
      <nav className={classNames('nav_bot', {
        open: activeTab === TABS.PARTNERS,
      })}
      >
        <div className="bot_t" onClick={() => { setActiveTab(TABS.PARTNERS) }}>Партнерам</div>
        <ul>
          <li><a href="/start-partnership">Начать сотрудничество</a></li>
        </ul>
      </nav>
      <nav className={classNames('nav_bot', {
        open: activeTab === TABS.COMPANY,
      })}
      >
        <div className="bot_t" onClick={() => { setActiveTab(TABS.COMPANY) }}>Компания</div>
        <ul>
          <li><a href="/about-us/">О нас</a></li>
          <li><a href={Urls['feedbacks:feedbacks']()}>Отзывы</a></li>
          <li><a href="/requisites/">Реквизиты</a></li>
          <li><a href="/contacts/">Контакты</a></li>
          <li><a href="/blog/">Блог</a></li>
        </ul>
      </nav>
    </>
  )
}

export default FooterMenu
