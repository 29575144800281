import { get } from 'lodash'
import {
  combineActions,
  handleActions,
} from 'redux-actions'

import {
  getSuccessType,
  getFailType,
} from 'common/api'
import { getSelfUser, logout } from 'common/store/auth/actions'
import {
  createComparisonProduct,
  deleteComparisonProduct,
  deleteComparisonCategory,
} from './actions'

const initialState = {
  products: [],
}

const comparisonReducer = handleActions(
  {
    [getSuccessType(getSelfUser)]: (state, action) => ({
      ...state,
      products: [...new Set(get(
        action,
        ['payload', 'comparisonProducts'],
        [],
      ).map(
        (product) => product.product,
      ))],
    }),
    [getSuccessType(deleteComparisonCategory)]: (state, action) => ({
      ...state,
      products: [...new Set(get(
        action,
        'payload',
        [],
      ).map(
        (product) => product.product,
      ))],
    }),
    [combineActions(
      deleteComparisonProduct,
      getSuccessType(deleteComparisonProduct),
    )]: (state, action) => ({
      ...state,
      products: state.products.filter((productId) => action.payload !== productId),
    }),
    [combineActions(
      createComparisonProduct,
      getSuccessType(createComparisonProduct),
      getFailType(deleteComparisonProduct),
    )]: (state, { payload }) => ({
      ...state,
      products: [...new Set(
        [
          ...get(state, 'products', []),
          payload,
        ],
      )],
    }),
    [combineActions(
      getSuccessType(logout),
      getFailType(getSelfUser),
    )]: () => initialState,
  },
  initialState,
)

export default comparisonReducer
