import React, { useCallback } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  createComparisonProduct,
  deleteComparisonProduct,
} from 'common/store/comparison/actions'
import style from '../index.module.sass'

const Comparison = ({ onComparisonPage, id, title }) => {
  const { comparisonProducts } = useSelector((state) => ({
    comparisonProducts: state.comparison.products,
  }))
  const dispatch = useDispatch()

  const inComparison = comparisonProducts.includes(id)
  const handleCompare = useCallback((event) => {
    event.preventDefault()

    if (inComparison) {
      dispatch(deleteComparisonProduct(id, title))
    } else {
      dispatch(createComparisonProduct(id, title))
    }
  }, [dispatch, id, inComparison, title])

  return (
    <a
      className={classNames((onComparisonPage) ? style.deleteIcon : 'prod_list_comp', {
        active: inComparison,
      })}
      href="#"
      onClick={handleCompare}
      role="button"
    >
      Сравнить
    </a>
  )
}

Comparison.propTypes = {
  onComparisonPage: PropTypes.bool,
  id: PropTypes.number,
  title: PropTypes.string,
}

export default Comparison
