import React from 'react'
import PropTypes from 'prop-types'

import OrdersPropTypes from 'shared-prop-types/orders'
import GoToPaymentButton from './components/GoToPaymentButton'
import Number from './components/Number'
import PayBefore from './components/PayBefore'
import Warning from './components/Warning'

const OrderPayment = ({ order, withNumber }) => {
  return (
    <div>
      <Warning withMargin={!withNumber} />
      {withNumber ? <Number orderNumber={order.number} /> : null}
      {withNumber ? <PayBefore orderId={order.id} payBefore={order.payBefore} /> : null}
      <GoToPaymentButton isSmall={withNumber} paymentId={order.payment.id} />
    </div>
  )
}

OrderPayment.defaultProps = {
  withNumber: false,
}

OrderPayment.propTypes = {
  order: OrdersPropTypes.Order,
  withNumber: PropTypes.string,
}

export default OrderPayment
