export const ACTION_TYPES = {
  SET_CART_STATE: 'CART/SET_CART_STATE',
  SET_ORDER_USER_DATA: 'CART/SET_ORDER_USER_DATA',
  GET_CART_USER_DATA: 'CART/GET_CART_USER_DATA',
  GET_CART_STATUS: 'CART/GET_CART_STATUS',
}

export const CART_FIRST_STEP = 1
export const CART_SECOND_STEP = 2
export const CART_THIRD_STEP = 3

export const ORDER_STATUSES = window.DJANGO_CONSTANTS?.ORDER_STATUSES || {}
