import { get } from 'lodash'
import {
  handleActions,
  combineActions,
} from 'redux-actions'

import {
  getSuccessType,
  getFailType,
} from 'common/api'
import {
  appendAddress,
  authenticate,
  cancelUnpaidOrder,
  closeLogin,
  deleteAddress,
  getSelfUser,
  logout,
  openLogin,
  returnToPhoneNumberTyping,
  sendAuthenticationCode,
  setUserCity,
  updatePhoneNumber,
  updateUserInfoData,
  updateUserTelegramAccount,
  LOGIN_MODAL_OPEN_TYPES,
} from './actions'

const getInitialLoginOpenType = () => {
  if (typeof window !== 'undefined' && window.location.pathname.startsWith('/s/')) {
    return LOGIN_MODAL_OPEN_TYPES.REDIRECT
  }

  return LOGIN_MODAL_OPEN_TYPES.SIGN_IN
}

const initialState = {
  addresses: [],
  appendAddressError: null,
  appendAddressLoading: false,
  authenticateCodeIsSent: false,
  authenticateError: null,
  authenticateLoading: false,
  awaitingPaymentOrders: [],
  city: null,
  deleteAddressLoading: false,
  email: null,
  employees: [],
  endorsedCoupon: null,
  firstName: '',
  id: null,
  isAuthenticated: false,
  isEmployee: null,
  isLoaded: false,
  isLoading: false,
  updateIsLoading: false,
  isStaff: false,
  isSuperuser: false,
  lastName: '',
  loginIsOpened: false,
  middleName: '',
  openType: getInitialLoginOpenType(),
  permissions: [],
  phoneNumber: '',
  temporaryPhoneNumber: '',
  resendingTime: null,
  sendCodeError: null,
  sendCodeLoading: false,
  telegramId: null,
  telegramUsername: null,
}

const getErrorMessage = (error, defaultMessage) => (typeof error === 'string' ? error : defaultMessage)

const authReducer = handleActions(
  {
    [sendAuthenticationCode]: (state) => ({
      ...state,
      sendCodeError: null,
      sendCodeLoading: true,
    }),
    [getSuccessType(sendAuthenticationCode)]: (state, action) => {
      const resendingTimeISO = get(action, ['payload', 'data', 'resendingTime'])
      const resendingTime = resendingTimeISO ? new Date(resendingTimeISO) : undefined
      const temporaryPhoneNumber = get(action, ['payload', 'data', 'phoneNumber'])

      return ({
        ...state,
        authenticateCodeIsSent: true,
        temporaryPhoneNumber,
        resendingTime,
        sendCodeError: null,
        sendCodeLoading: false,
      })
    },
    [getFailType(sendAuthenticationCode)]: (state, action) => ({
      ...state,
      authenticateError: null,
      authenticateCodeIsSent: false,
      resendingTime: null,
      sendCodeLoading: false,
      sendCodeError: getErrorMessage(
        get(action, ['payload', 'data', 'errors']),
        'Не удалось отправить код. Проверьте введенный номер телефона',
      ),
    }),
    [authenticate]: (state) => ({
      ...state,
      authenticateLoading: true,
    }),
    [getSuccessType(authenticate)]: (state) => ({
      ...state,
      isAuthenticated: true,
      authenticateLoading: false,
    }),
    [getFailType(authenticate)]: (state, action) => ({
      ...state,
      isAuthenticated: false,
      authenticateLoading: false,
      authenticateError: getErrorMessage(
        get(action, ['payload', 'data', 'errors']),
        'Не удалость войти на портал.',
      ),
    }),
    [combineActions(
      getSuccessType(logout),
      getFailType(getSelfUser),
    )]: () => ({
      ...initialState,
      isAuthenticated: false,
      isLoaded: true,
    }),
    [setUserCity]: (state) => ({
      ...state,
      updateIsLoading: true,
    }),
    [getSuccessType(setUserCity)]: (state, action) => ({
      ...state,
      id: get(action, ['payload', 'data', 'id'], state.id),
      city: get(action, ['payload', 'data', 'city'], state.city),
      postalCode: get(action, ['payload', 'data', 'postalCode'], null),
      cityName: get(action, ['payload', 'data', 'cityName'], null),
      updateIsLoading: false,
    }),
    [getFailType(setUserCity)]: (state) => ({
      ...state,
      updateIsLoading: false,
    }),
    [getSuccessType(updateUserTelegramAccount)]: (state, action) => ({
      ...state,
      firstName: get(action, ['payload', 'data', 'firstName']),
      telegramId: get(action, ['payload', 'data', 'telegramId']),
      telegramUsername: get(action, ['payload', 'data', 'telegramUsername']),
    }),
    [getSelfUser]: (state) => ({
      ...state,
      isLoaded: false,
      isLoading: true,
    }),
    [getSuccessType(getSelfUser)]: (state, action) => ({
      ...state,
      isAuthenticated: get(action, ['payload', 'isAuthenticated'], false),
      addresses: get(action, ['payload', 'addresses'], []),
      awaitingPaymentOrders: get(action, ['payload', 'awaitingPaymentOrders'], []),
      city: get(action, ['payload', 'city']) || get(state, 'city'),
      email: get(action, ['payload', 'email']),
      employees: get(action, ['payload', 'employees'], []),
      endorsedCoupon: get(action, ['payload', 'endorsedCoupon']),
      firstName: get(action, ['payload', 'firstName']),
      id: get(action, ['payload', 'id']),
      isEmployee: get(action, ['payload', 'isEmployee']),
      isLoaded: true,
      isLoading: false,
      isStaff: get(action, ['payload', 'isStaff']),
      isSuperuser: get(action, ['payload', 'isSuperuser']),
      lastName: get(action, ['payload', 'lastName']),
      middleName: get(action, ['payload', 'middleName']),
      permissions: get(action, ['payload', 'permissions'], []),
      phoneNumber: get(action, ['payload', 'phoneNumber']) || '',
      temporaryPhoneNumber: get(action, ['payload', 'phoneNumber']) || '',
      telegramId: get(action, ['payload', 'telegramId']),
      telegramUsername: get(action, ['payload', 'telegramUsername']),
    }),
    [returnToPhoneNumberTyping]: (state) => ({
      ...state,
      authenticateCodeIsSent: false,
      authenticateError: null,
      authenticateLoading: false,
      resendingTime: null,
      sendCodeError: null,
      sendCodeLoading: false,
    }),
    [openLogin]: (state, action) => ({ ...state,
      loginIsOpened: true,
      openType: get(action, ['payload', 'openType']) }),
    [closeLogin]: (state) => ({
      ...state,
      loginIsOpened: false,
    }),
    [combineActions(
      updateUserInfoData,
      getFailType(updateUserInfoData),
      getSuccessType(updateUserInfoData),
    )]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [appendAddress]: (state) => ({
      ...state,
      appendAddressLoading: true,
      appendAddressError: null,
    }),
    [getFailType(appendAddress)]: (state, action) => ({
      ...state,
      appendAddressLoading: false,
      appendAddressError: action.payload,
    }),
    [getSuccessType(appendAddress)]: (state, action) => ({
      ...state,
      addresses: state.addresses.find((address) => address.id === action.payload.id) ? state.addresses : [
        ...state.addresses,
        action.payload,
      ],
      appendAddressLoading: false,
      appendAddressError: null,
    }),
    [deleteAddress]: (state) => ({
      ...state,
      deleteAddressLoading: true,
    }),
    [getSuccessType(deleteAddress)]: (state, action) => ({
      ...state,
      addresses: state.addresses.filter((address) => address.id !== action.payload),
      deleteAddressLoading: false,
    }),
    [getFailType(deleteAddress)]: (state) => ({
      ...state,
      deleteAddressLoading: false,
    }),
    [updatePhoneNumber]: (state) => ({
      ...state,
      authenticateLoading: true,
    }),
    [getSuccessType(updatePhoneNumber)]: (state, action) => ({
      ...state,
      phoneNumber: action.payload.phoneNumber,
      authenticateLoading: false,
    }),
    [getFailType(updatePhoneNumber)]: (state) => ({
      ...state,
      authenticateLoading: false,
    }),
    [cancelUnpaidOrder]: (state, action) => ({
      ...state,
      awaitingPaymentOrders: state.awaitingPaymentOrders.filter(
        (order) => order.id !== action.payload,
      ),
    }),
  },
  initialState,
)

export default authReducer
