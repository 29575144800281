import { build, isRelative } from './urls'

export const goBack = (event) => {
  if (event) {
    event.preventDefault()
  }

  window.history.back()

  return false
}

export const visitUrl = (url) => {
  window.location.href = isRelative(url) ? build(url) : url
}
