import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import { BREAKPOINTS } from 'common/utils/breakpoints'

class MainPagePopularCategoriesSlider extends React.Component {
  settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: BREAKPOINTS.TABLET,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: BREAKPOINTS.MOBILE,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
          dots: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          dots: false,
        },
      },
    ],
  }

  render() {
    const { slides } = this.props

    return (
      <Slider {...this.settings}>
        {slides.map((slide) => {
          return (
            <div key={slide.id} className="cats_slide">
              <a href={slide.url}>
                <img alt={slide.categoryTitle} className="cats_slide_img" src={slide.backgroundImage.url} srcSet={`${slide.backgroundImage.url2X} 2x`} />
                <span className="cats_slide_t">{slide.categoryTitle}</span>
              </a>
            </div>
          )
        })}
      </Slider>
    )
  }
}

MainPagePopularCategoriesSlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({
    backgroundImage: PropTypes.shape({
      url: PropTypes.string,
      url2X: PropTypes.string,
    }),
    categoryTitle: PropTypes.string,
    id: PropTypes.number,
    url: PropTypes.string,
  })),
}

export default MainPagePopularCategoriesSlider
