import { useState, useEffect } from 'react'
import { client } from 'common/api'

export default (initialProducts) => {
  const [products, setProducts] = useState(initialProducts)
  const [isLoading, setLoading] = useState(true)

  useEffect(async () => {
    const urlPath = Urls['apiV01:productsList']()
    const queryParams = 'discount__gt=0&limit=25&with_brands=false&is_available=true&image_exists=true&offset=-1'
    const url = `${urlPath}?${queryParams}`

    try {
      const response = await client.fetch(url)
      setProducts([...response.data.products.results, ...initialProducts])
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }, [setProducts, setLoading, initialProducts])


  return {
    products,
    isLoading,
  }
}
