export const ACTION_TYPES = {
  CLOSE_SELECT_CITY_MODAL: 'LOCATIONS/CLOSE_SELECT_CITY_MODAL',
  GET_CITIES: 'LOCATIONS/GET_CITIES',
  OPEN_SELECT_CITY_MODAL: 'LOCATIONS/OPEN_SELECT_CITY_MODAL',
  UPDATE_SELECT_CITY_MODAL_OPEN_STATE: 'LOCATIONS/UPDATE_SELECT_CITY_MODAL_OPEN_STATE',
}

export const CITIES_LIMIT = 44

export const DEFAULT_COORDINATES = {
  latitude: 55.74954,
  longitude: 37.621587,
}
