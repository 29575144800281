import { get } from 'lodash'

import { getSuccessType, getFailType } from 'common/api'
import { actionTypes } from './constants'

export const initialState = {
  foundBrands: [],
  foundProducts: [],
  searchHistory: [],
  isLoading: false,
  isTyping: false,
}

const reducer = (state, action) => {
  switch (action.type) {
  case (getSuccessType(actionTypes.GET_SEARCH_HISTORY)):
    return {
      ...state,
      searchHistory: action.payload,
    }
  case (getSuccessType(actionTypes.CLEAR_SEARCH_HISTORY)):
    return {
      ...state,
      searchHistory: [],
    }
  case actionTypes.PERFORM_SEARCH:
    return {
      ...state,
      isLoading: true,
    }
  case (getSuccessType(actionTypes.PERFORM_SEARCH)):
    return {
      ...state,
      foundBrands: get(action, ['payload', 'brands', 'results'], []),
      foundProducts: get(action, ['payload', 'products', 'results'], []),
      isLoading: false,
      isTyping: false,
    }
  case (getFailType(actionTypes.PERFORM_SEARCH)):
    return {
      ...state,
      isLoading: false,
      isTyping: false,
    }
  case actionTypes.START_TYPING:
    return {
      ...state,
      isTyping: true,
    }
  default:
    return state
  }
}

export default reducer
