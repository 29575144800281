import Cookies from 'js-cookie'

export const YANDEX_METRIKA_COUNTER_ID = 69780319
const CLIENT_ID_COOKIE = '_ym_uid'

export const GOALS = {
  PROCEED_TO_CHECKOUT_GOAL_ID: 'proceed_to_checkout',
  CONFIRM_SMS_CODE_LOGIN_GOAL_ID: 'to_get_the_code',
  CONFIRM_SMS_CODE_CHECKOUT_GOAL_ID: 'confirm_number',
  ADD_TO_CART_GOAL_ID: 'goal-add-to-card',
  ERROR: 'error',
}

export const reachGoal = (goalId) => {
  if (typeof ym !== 'undefined') {
    // eslint-disable-next-line no-undef
    ym(YANDEX_METRIKA_COUNTER_ID, 'reachGoal', goalId)
  }
}

export const getClientId = (callback) => {
  if (typeof ym !== 'undefined') {
    // eslint-disable-next-line no-undef
    ym(YANDEX_METRIKA_COUNTER_ID, 'getClientID', callback)
  }
}

export const getClientIdFromCookie = () => Cookies.get(CLIENT_ID_COOKIE)

export const ymIsInitialized = () => typeof ym !== 'undefined'
