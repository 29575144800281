import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.sass'

const Number = ({ orderNumber }) => (
  <div className={styles.number}>Заказ №{orderNumber}</div>
)

Number.propTypes = {
  orderNumber: PropTypes.string,
}

export default Number
