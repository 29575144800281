import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import hoistStatics from 'hoist-non-react-statics'

function withRouter(Component) {
  const ConnectedComponent = (props) => (
    <Router>
      <Component {...props} />
    </Router>
  )

  return hoistStatics(ConnectedComponent, Component)
}

export default withRouter
