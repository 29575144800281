import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.sass'

const Warning = ({ withMargin }) => (
  <div className={
    classNames(styles.warning, {
      [styles.warningWithMargin]: withMargin,
    })
  }
  >
    Ожидает оплаты <div className={styles.warningIcon}><div className={styles.warningIconSign}>!</div></div>
  </div>
)

Warning.propTypes = {
  withMargin: false,
}

Warning.propTypes = {
  withMargin: PropTypes.bool,
}

export default Warning
