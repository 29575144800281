import PropTypes from 'prop-types'

const SrcSet = PropTypes.shape({
  url: PropTypes.string,
  scaleSize: PropTypes.string,
})

const Source = PropTypes.shape({
  src: PropTypes.string,
  srcSet: PropTypes.arrayOf(SrcSet),
})

const Picture = PropTypes.shape({
  src: PropTypes.string,
  srcSet: PropTypes.arrayOf(SrcSet),
  sources: PropTypes.arrayOf(Source),
})

export default {
  Picture,
}
