export const SESSION_COOKIE_KEY = 'sessionid'

export const APP_NAME = 'AUTH'

export const ACTION_TYPES = {
  APPEND_ADDRESS: `${APP_NAME}/APPEND_ADDRESS`,
  AUTHENTICATE: `${APP_NAME}/AUTHENTICATE`,
  CANCEL_UNPAID_ORDER: `${APP_NAME}/CANCEL_UNPAID_ORDER`,
  CLOSE_LOGIN: `${APP_NAME}/CLOSE_LOGIN`,
  DELETE_ADDRESS: `${APP_NAME}/DELETE_ADDRESS`,
  GET_SELF_USER: `${APP_NAME}/GET_SELF_USER`,
  LOGOUT: `${APP_NAME}/LOGOUT`,
  OPEN_LOGIN: `${APP_NAME}/OPEN_LOGIN`,
  RETURN_TO_PHONE_NUMBER: `${APP_NAME}/RETURN_TO_PHONE_NUMBER`,
  SEND_AUTHENTICATION_CODE: `${APP_NAME}/SEND_AUTHENTICATION_CODE`,
  SET_USER_CITY: `${APP_NAME}/SET_USER_CITY`,
  SET_USER_TELEGRAM_ACCOUNT: `${APP_NAME}/SET_USER_TELEGRAM_ACCOUNT`,
  UPDATE_PHONE_NUMBER: `${APP_NAME}/UPDATE_PHONE_NUMBER`,
  UPDATE_USER_INFO: `${APP_NAME}/UPDATE_USER_INFO`,
  UPDATE_ORDER_USER_INFO: `${APP_NAME}/UPDATE_ORDER_USER_INFO`,
}

export const USER_INFO_FIELDS = [
  'email',
  'firstName',
  'lastName',
  'middleName',
]

export const ORDER_USER_DATA_FIELDS = [
  'email',
  'firstName',
  'lastName',
  'middleName',
  'phoneNumber',
]
