import React from 'react'
import { useSelector } from 'react-redux'

import usePrevDependencies from 'common/hooks/use-prev-dependencies'
import selectIsAuthenticated from 'common/store/auth/selectors/is-authenticated'

const RedirectToOrdersOnLogin = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  usePrevDependencies((prevIsAuthenticated) => {
    if (!prevIsAuthenticated && isAuthenticated && typeof document !== 'undefined') {
      window.location.href = Urls['users:profile']('orders')
    }
  }, [isAuthenticated])

  return (
    <div />
  )
}

export default RedirectToOrdersOnLogin
