import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import formatPrice from 'common/utils/formatters/price'

import styles from './index.module.sass'

const Price = ({ biggestOldPrice, orderedAt, smallestPrice }) => {
  const outOfStock = !(biggestOldPrice || smallestPrice)
  const showOldPrice = biggestOldPrice && biggestOldPrice > smallestPrice

  return (
    <div className={classNames({
      prod_list_price_old: showOldPrice,
      prod_list_price: !showOldPrice,
      [styles.repeatPrice]: orderedAt,
    })}
    >
      {
        outOfStock ? (
          <div className="prod_list_nvn">нет в наличии</div>
        ) : (
          <>
            <span className="current">{formatPrice(smallestPrice)}</span>
            {showOldPrice ? <span className="old">{formatPrice(biggestOldPrice)}</span> : null}
          </>
        )
      }
    </div>
  )
}

Price.propTypes = {
  biggestOldPrice: PropTypes.number,
  orderedAt: PropTypes.string,
  smallestPrice: PropTypes.number,
}

export default Price
