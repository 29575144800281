import { get } from 'lodash'
import { createAction } from 'redux-actions'

import { client, getFailType, getSuccessType } from 'common/api'
import { showNotification } from 'common/store/notifications/actions'
import { setUserCity as setAnonymousUserCity } from './anonymous'
import {
  updateUserTelegramAccount as updateAuthorizedUserTelegramAccount,
  getAppendUserAddress,
} from './authorized'
import {
  ACTION_TYPES,
  USER_INFO_FIELDS,
} from '../constants'
import named from '../../named'

export {
  authenticate,
  sendAuthenticationCode,
  logout,
} from './auth'
export {
  cancelUnpaidOrder,
  getAppendUserAddress,
  updatePhoneNumber,
} from './authorized'
export { default as getSelfUser } from './get-self-user'
export {
  openLogin,
  closeLogin,
} from './login-modal'

export const setUserCity = named(
  ACTION_TYPES.SET_USER_CITY,
  (cityId) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_USER_CITY })

    const response = await client.fetch(Urls['apiV01:userSelfList'](), {
      method: 'PATCH',
      data: {
        city: cityId,
      },
    })

    if (response.ok) {
      dispatch({
        type: getSuccessType(ACTION_TYPES.SET_USER_CITY),
        payload: response,
      })
    } else {
      dispatch(setAnonymousUserCity(cityId))
    }
  },
)

export const updateUserTelegramAccount = named(
  ACTION_TYPES.SET_USER_TELEGRAM_ACCOUNT,
  (telegramLoginData) => async (dispatch, getState) => {
    const state = getState()
    const isAuthenticated = state.auth.isAuthenticated

    dispatch({ type: ACTION_TYPES.SET_USER_TELEGRAM_ACCOUNT })

    if (isAuthenticated) dispatch(updateAuthorizedUserTelegramAccount(telegramLoginData))
  },
)

export const returnToPhoneNumberTyping = createAction(ACTION_TYPES.RETURN_TO_PHONE_NUMBER)

export const LOGIN_MODAL_OPEN_TYPES = {
  SIGN_IN: 0,
  DURING_CHECKOUT: 1,
  UPDATE_PHONE_NUMBER: 2,
  REDIRECT: 3,
}

export const updateUserInfoData = named(
  ACTION_TYPES.UPDATE_USER_INFO,
  (fieldName, value) => (dispatch) => {
    if (USER_INFO_FIELDS.includes(fieldName)) {
      const updated = {
        [fieldName]: value,
      }

      dispatch({
        type: ACTION_TYPES.UPDATE_USER_INFO,
        payload: updated,
      })
    }
  },
)

export const updateUserTemporaryPhoneNumber = named(
  ACTION_TYPES.UPDATE_USER_INFO,
  (value) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_USER_INFO,
      payload: {
        temporaryPhoneNumber: value,
      },
    })
  },
)

export const appendAddress = named(
  ACTION_TYPES.APPEND_ADDRESS,
  (address, callback) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.APPEND_ADDRESS })

    const response = await client.fetch(
      getAppendUserAddress(),
      {
        method: 'POST',
        data: address,
      },
    )

    if (response.ok) {
      dispatch({
        type: getSuccessType(ACTION_TYPES.APPEND_ADDRESS),
        payload: response.data,
      })

      if (callback) {
        callback(response.data)
      }
    } else {
      const city = get(address, 'city')
      const error = `К сожалению, доставка в ${get(city, 'name')} не поддерживается`
      dispatch({
        type: getFailType(ACTION_TYPES.APPEND_ADDRESS),
        payload: error,
      })
      dispatch(
        showNotification({
          title: error,
          isError: true,
        }),
      )
    }
  },
)

export const deleteAddress = named(
  ACTION_TYPES.DELETE_ADDRESS,
  (addressId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.DELETE_ADDRESS,
    })

    const response = await client.fetch(Urls['apiV01:userAddressesDetail'](addressId), {
      method: 'DELETE',
    })

    if (response.ok) {
      dispatch({
        type: getSuccessType(ACTION_TYPES.DELETE_ADDRESS),
        payload: addressId,
      })
    } else {
      dispatch({
        type: getFailType(ACTION_TYPES.DELETE_ADDRESS),
      })
    }
  },
)
