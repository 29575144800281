import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import CodeForm from './CodeForm'
import PhoneNumberForm from './PhoneNumberForm'

const Login = ({ onClose }) => {
  const {
    authenticateCodeIsSent,
  } = useSelector(
    (state) => ({
      authenticateCodeIsSent: state.auth.authenticateCodeIsSent,
    }),
  )

  return (authenticateCodeIsSent ? (
    <CodeForm onClose={onClose} />
  ) : (
    <PhoneNumberForm onClose={onClose} />
  )
  )
}

Login.propTypes = {
  onClose: PropTypes.func,
}

export default Login
