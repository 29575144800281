import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import style from '../index.module.sass'

export default function MobileNav() {
  const { comparisonProducts, favoriteProducts, ordersProducts } = useSelector((state) => ({
    comparisonProducts: state.comparison.products,
    favoriteProducts: state.favorite.products,
    ordersProducts: state.orders.products,
  }))

  const comparisonProductsCount = get(comparisonProducts, 'length', 0)
  const favoriteProductsCount = get(favoriteProducts, 'length', 0)
  const ordersProductsCount = Object.keys(ordersProducts).length

  return (
    <div className={style.navMob}>
      <div className={style.navMobIcons}>
        <ul>
          <li className={style.navMobCart}><a href={Urls['orders:new']()}>Корзина {ordersProductsCount ? `(${ordersProductsCount})` : null}</a></li>
          <li className={style.navMobUser}><a href={Urls['users:profile']()}>Профиль</a></li>
          <li className={style.navMobLike}><a href={Urls['catalog:product-favorite']()}>Избранное {favoriteProductsCount ? `(${favoriteProductsCount})` : null}</a></li>
          <li className={style.navMobComp}><a href={Urls['catalog:product-comparison']()}>Сравнение товаров {comparisonProductsCount ? `(${comparisonProductsCount})` : null}</a></li>
        </ul>
      </div>
      <ul>
        <li><a href="/about-us/">О маркетплейсе</a></li>
        <li><a href={Urls['users:profile']('orders')}>Где мой заказ?</a></li>
        <li><a href="/contacts/">Контакты</a></li>
        <li><a href={Urls['feedbacks:feedbacks']()}>Отзывы</a></li>
        <li><a href={Urls['pages:blog']()}>Блог</a></li>
      </ul>
    </div>
  )
}
