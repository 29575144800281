import { parse, stringify } from 'querystring'
import { get, isUndefined, last, snakeCase } from 'lodash'
import transformKeys from 'transform-keys'

export const build = (relativePath) => {
  const origin = `${window.location.protocol}//${window.location.host}`

  return [origin, relativePath.replace(/^\//, '')].join('/')
}

const absoluteUrlPattern = /^(http:|https:|\/\/")/

export const isRelative = (url) => !absoluteUrlPattern.test(url)

const fileNameReplacePattern = /^.*[\\/]/

export const getFileName = (path) => path.replace(fileNameReplacePattern, '')

export const extractSearchQuery = (url) => {
  const search = last(url.split('?'))
  return search ? parse(search) : {}
}

export const getSearch = (params) => {
  const searchState = Object.keys(params).reduce((searchParams, paramName) => {
    const value = get(params, paramName)

    return !isUndefined(value) ? {
      ...searchParams,
      [paramName]: value,
    } : searchParams
  }, {})

  const searchString = stringify(transformKeys(searchState, snakeCase))

  return searchString && `?${searchString}`
}
