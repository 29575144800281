import React from 'react'
import style from '../index.module.sass'

const MenuFooter = () => {
  const {
    LMARKT_PHONE_NUMBER,
    LMARKT_PHONE_FORMATTED,
    LMARKT_SOCIAL_LINK_FACEBOOK,
    LMARKT_SOCIAL_LINK_INSTAGRAM,
    LMARKT_SOCIAL_LINK_TELEGRAM,
    LMARKT_SOCIAL_LINK_VK,
  } = window.DJANGO_CONSTANTS

  return (
    <>
      <div className={style.navMobTel}><a href={`tel:${LMARKT_PHONE_NUMBER}`}>{LMARKT_PHONE_FORMATTED}</a></div>
      <div className={`${style.navMobSoc} ${style.soc}`}>
        <a aria-label="vk" className={style.socVk} href={LMARKT_SOCIAL_LINK_VK} />
        <a aria-label="fb" className={style.socFb} href={LMARKT_SOCIAL_LINK_FACEBOOK} />
        <a aria-label="in" className={style.socIn} href={LMARKT_SOCIAL_LINK_INSTAGRAM} />
        <a aria-label="tg" className={style.socTg} href={LMARKT_SOCIAL_LINK_TELEGRAM} />
      </div>
    </>
  )
}

export default MenuFooter
