import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import { BREAKPOINTS } from 'common/utils/breakpoints'
import style from './index.module.sass'

class MainPageProductsTypesSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hidden: true,
    }
  }

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: BREAKPOINTS.SMALLDESKTOP,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: BREAKPOINTS.TABLET,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: BREAKPOINTS.MOBILE,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          dots: false,
        },
      },
    ],
  }

  render() {
    const { categories } = this.props
    const { hidden } = this.state

    return (
      <div className={`${(hidden) ? 'povids_over' : ''}`}>
        <div className="povids_slider">
          <Slider {...this.settings}>
            {categories.map((category) => {
              return (
                <div key={category.id} className="povid_slide">
                  <div className="povid">
                    <div className={classNames('povid_t', style.instrumentIcon)}>
                      <a
                        href={category.url}
                      >
                        <img alt={category.title} src={category.instrumentsIcon} />
                        {category.title}
                      </a>
                    </div>
                    <ul>
                      {category.children.map(
                        (childrenCategory) => (
                          <li key={childrenCategory.id}>
                            <a href={childrenCategory.url}>
                              {childrenCategory.title}
                            </a>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
        <span
          className={`povids_show ${(!hidden) ? style.hide : ''}`}
          onClick={() => { this.setState({ hidden: false }) }}
        />
      </div>
    )
  }
}

const CategoryType = function () {
  return PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    instrumentsIcon: PropTypes.string,
    children: PropTypes.arrayOf(CategoryType),
    url: PropTypes.string,
    // eslint-disable-next-line prefer-rest-params
  }).apply(this, arguments)
}

MainPageProductsTypesSlider.propTypes = {
  categories: PropTypes.arrayOf(CategoryType),
}

export default MainPageProductsTypesSlider
