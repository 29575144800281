import React, { useEffect, useState } from 'react'
import ProductsCarousel from 'store/components/ProductsCarousel'
import PropTypes from "prop-types";

import { useRandomSalesProducts } from './hooks'


const SalesProductsCarousel = ({ initialProducts }) => {
  const { products, isLoading } = useRandomSalesProducts(initialProducts)

  return (
    <ProductsCarousel isLoading={isLoading} products={products} />
  )
}

SalesProductsCarousel.propTypes = {
  initialProducts: PropTypes.arrayOf(PropTypes.shape({
    actualOfferId: PropTypes.number,
    biggestOldPrice: PropTypes.number,
    id: PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.shape({url: PropTypes.string})),
    smallestPrice: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
  })),
}

export default SalesProductsCarousel;
