import React, { useMemo } from 'react'
import { get } from 'lodash'
import propTypes from 'prop-types'
import Slider from 'react-slick'

const SLIDES_TO_SHOW = 4

const baseSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: SLIDES_TO_SHOW,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
}

const Carousel = ({ children }) => {
  const childrenCount = get(children, 'length', 0)
  const smallChildrenCount = childrenCount < SLIDES_TO_SHOW
  const settings = useMemo(
    () => ({
      ...baseSettings,
      // Так как для пролистывания бесконечного слайдера создается дополнительный элемет
      // для количества элементов меньше SLIDES_TO_SHOW не нужна бесконечная прокрутка
      infinite: !smallChildrenCount,
    }),
    [children],
  )

  return (
    <Slider {...settings}>
      {children}
    </Slider>
  )
}

Carousel.propTypes = {
  children: propTypes.node,
}

export default Carousel
