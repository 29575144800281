import { get } from 'lodash'
import {
  combineActions,
  handleActions,
} from 'redux-actions'

import {
  getSuccessType,
  getFailType,
} from 'common/api'
import {
  getSelfUser,
  logout,
} from 'common/store/auth/actions'
import {
  createOrderProduct,
  deleteOrderProduct,
  submitOrders,
  updateOrderProductCount,
  updateOrderProducts,
  updateOrdersComment,
} from './actions'
import normalize from '../normalize'

const deleteProduct = (originProducts, offerForDelete) => {
  const offers = Object.keys(originProducts).map((offer) => Number.parseInt(offer, 10))

  return offers.reduce((products, offer) => (offer !== offerForDelete ? {
    ...products,
    [offer]: originProducts[offer],
  } : products), {})
}

const toFlatErrors = (errors) => (typeof errors === 'object' ? Object.keys(errors).map(
  (errorKey) => errors[errorKey],
).flat() : [])

const initialState = {
  offersLoading: [],

  // orders products

  products: {},
  lastProductsCount: {},

  // Errors
  errors: {},

  // Submit state

  submitInProgress: false,
}

const ordersReducer = handleActions({
  [combineActions(createOrderProduct, updateOrderProductCount)]: (state, action) => {
    const { offer, count } = action.payload

    return {
      ...state,
      products: {
        ...state.products,
        [offer]: count,
      },
      errors: {
        ...state.errors,
        [offer]: [],
      },
      offersLoading: [...new Set([
        ...state.offersLoading,
        offer,
      ])],
    }
  },
  [combineActions(
    getSuccessType(createOrderProduct),
    getSuccessType(updateOrderProductCount),
  )]: (state, action) => {
    const { offer: { id: offer }, count = 0 } = action.payload

    return {
      ...state,
      products: {
        ...get(state, 'products', {}),
        [offer]: count,
      },
      lastProductsCount: {
        ...state.lastProductsCount,
        [offer]: count,
      },
      errors: {
        ...state.errors,
        [offer]: [],
      },
      offersLoading: state.offersLoading.filter(
        (loadingOffer) => loadingOffer !== offer,
      ),
    }
  },
  [combineActions(
    getFailType(createOrderProduct),
    getFailType(updateOrderProductCount),
    getFailType(deleteOrderProduct),
  )]: (state, action) => {
    const { offer, errors } = action.payload

    return {
      ...state,
      products: {
        ...state.products,
        [offer]: state.lastProductsCount[offer],
      },
      errors: {
        ...state.errors,
        [offer]: toFlatErrors(errors),
      },
      offersLoading: state.offersLoading.filter(
        (loadingOffer) => loadingOffer !== offer,
      ),
    }
  },
  [deleteOrderProduct]: (state, action) => ({
    ...state,
    products: deleteProduct(state.products, action.payload),
    offersLoading: [...new Set([
      ...state.offersLoading,
      action.payload,
    ])],
  }),
  [getSuccessType(deleteOrderProduct)]: (state, action) => ({
    ...state,
    products: deleteProduct(state.products, action.payload),
    lastProductsCount: deleteProduct(state.lastProductsCount, action.payload),
    offersLoading: state.offersLoading.filter(
      (loadingOffer) => loadingOffer !== action.payload,
    ),
  }),
  [getSuccessType(getSelfUser)]: (state, action) => {
    const normalizedProductsCount = normalize(
      action?.payload?.ordersProducts || [],
      'offer',
      'count',
    )

    return {
      ...state,
      products: normalizedProductsCount,
      lastProductsCount: normalizedProductsCount,
    }
  },
  [submitOrders]: (state) => ({
    ...state,
    submitInProgress: true,
  }),
  [combineActions(
    getSuccessType(submitOrders),
    getFailType(submitOrders),
  )]: (state) => ({
    ...state,
    submitInProgress: false,
  }),
  [updateOrderProducts]: (state, action) => {
    const orderProducts = (action?.payload || []).map(
      (orderProduct) => ({
        ...orderProduct,
        offer: orderProduct?.offer?.id,
      }),
    )
    const normalizedProductsCount = normalize(
      orderProducts || [],
      'offer',
      'count',
    )

    return {
      ...state,
      products: normalizedProductsCount,
      lastProductsCount: normalizedProductsCount,
    }
  },
  [combineActions(
    getSuccessType(logout),
    getFailType(getSelfUser),
  )]: () => initialState,
  [updateOrdersComment]: (state, action) => ({
    ...state,
    comment: get(action, ['payload', 'comment']),
  }),
}, initialState)

export default ordersReducer
